import React, { useEffect } from "react";
import Recaptcha from 'react-recaptcha';
import { useDispatch, useSelector } from "react-redux";
import { closePopup, selectPopup, selectSubmitEvent, submitForm } from "../store";
import { useForm } from "react-hook-form";

export default function Popup(props) {
    const dispatch = useDispatch();
    const popup = useSelector(selectPopup);
    const submitEvent = useSelector(selectSubmitEvent);
    let event = popup?.event;

    // Form - start
    const { register, handleSubmit, setValue, trigger, formState: { errors } } = useForm();

    const submitFormCallback = (data) => {
        dispatch(submitForm(data));
    };

    const onRecaptchaVerifyCallback = response => {
        setValue('g-recaptcha-response', response);
        (async () => {
            await trigger('g-recaptcha-response');
        })();
    };
    const onRecaptchaExpiredCallback = () => {
        setValue('g-recaptcha-response', "");
        (async () => {
            await trigger('g-recaptcha-response');
        })();
    };

    if (event) {
        setValue("event_id", event.id);
    }

    // Set redirect to thank you page
    useEffect(() => {
        if(submitEvent.status === "done") {
            document.location.href = "/thankyou.php"
        }
    }, [submitEvent])

    // Form - end

    return (<>
        <div className={`popup w-screen top-0 left-0 flex items-center justify-center h-screen overflow-y-auto overflow-x-hidden fixed ${popup.isOpen ? "block" : "hidden"}`}>
            <div className="lg:bg-white w-full h-full py-8 lg:p-0 lg:w-9/12 lg:h-4/6 relative z-30 rounded-md flex flex-col">
                <div className="absolute left-0 w-full top-0 lg:left-auto lg:top-auto lg:relative">
                    <button
                        className="popup__close-button top-0 right-0 lg:top-5 lg:right-5 absolute icon-cancel text-blue opacity-50 text-xl lg:text-base"
                        onClick={() => dispatch(closePopup())}
                    />
                    <div slot="header" className="hidden lg:flex justify-center">
                        <div className="w-32 h-32 p-4 absolute -mt-16 flex items-center justify-center bg-background1 rounded-full">
                            <img src="../images/logo.svg" alt="logo"/>
                        </div>
                    </div>
                </div>
                {submitEvent.status === "done" ? (<>Спасибо за заявку!</>) : null}
                {submitEvent.status === "loading" ? (<>Идет отправка... </>) : null}

                {(submitEvent.status === "idle" || submitEvent.status === "error") && event ? (
                    <div className="flex-1 px-4 lg:p-0">
                        <div slot="content" className="grid lg:grid-cols-2 gap-6 lg:gap-0 h-full">
                            <div className="p-3 lg:py-16 lg:px-12 bg-white lg:bg-background2 grid grid-cols-2 rounded lg:rounded-none">
                                <p className="text-blue font-bold opacity-50 col-start-1 col-end-2 lg:col-end-3 row-start-1 row-end-2">{event.__weekday}</p>
                                <p className="text-blue text-lg lg:text-xl font-bold col-start-1 col-end-2 lg:col-end-3 row-start-2 row-end-3">{event.__date_str}</p>
                                <p className="block lg:hidden col-start-2 col-end-3 row-start-1 row-end-2 text-blue font-bold opacity-50 text-right lg:text-left">стоимость</p>
                                <p className="lg:mt-12 mt-8 text-blue font-bold opacity-50 lg:text-xl text-lg col-start-1 col-end-3 row-start-3 row-end-4">МЕСТО ПРОВЕДЕНИЯ</p>
                                <p className="text-blue font-extrabold lg:text-2xl text-xl leading-tight col-start-1 col-end-3 row-start-4 row-end-5">{event.cafe_name}</p>
                                <div className="mt-20 col-start-1 col-end-3 row-start-5 row-end-6 hidden lg:block">
                                <span className="flex items-center">
                                    <i className="icon-location text-blue opacity-50"/>
                                    <span className="text-blue opacity-50 text-lg font-bold ml-5">
                                        {event.cafe_address}
                                    </span>
                                </span>
                                    <span className="flex items-center">
                                    <i className="icon-metro text-blue opacity-50"/>
                                    <span className="text-blue opacity-50 text-lg font-bold ml-5">
                                        {event.cafe_metro}
                                    </span>
                                </span>
                                </div>
                                <div className="col-start-1 col-end-2 row-start-6 row-end-7 hidden lg:block">
                                    <div className="bg-white relative rounded py-3 px-4 w-max">
                                        <i className="icon-mark absolute text-red -top-2 left-1"/>
                                        <span className="uppercase text-blue font-bold">{event.age_group_text}</span>
                                        <span className="grid grid-cols-2 gap-6">
                                        <span className="flex items-center">
                                            <i className="icon-female text-red"/>
                                            <span className="text-red">{event.min_age_f}-{event.max_age_f}</span>
                                        </span>
                                        <span className="flex items-center">
                                            <i className="icon-male text-blue-light"/>
                                            <span className="text-blue-light">{event.min_age_m}-{event.max_age_m}</span>
                                        </span>
                                    </span>
                                    </div>
                                </div>

                                <div className="col-start-2 col-end-3 lg:row-start-auto lg:row-end-auto  row-start-2 row-end-3 flex flex-col items-end">
                                    <p className="leading-none font-extrabold text-yellow lg:text-xl text-lg text-right lg:text-left popup__current-price">{event.price}
                                        ₽</p>
                                    <s className="leading-none hidden lg:block text-blue opacity-50 text-lg font-extrabold whitespace-nowrap popup__old-price">{event.old_price}
                                        ₽</s>
                                </div>

                            </div>

                            <div className="lg:bg-background1 flex justify-center lg:py-16 lg:px-12">
                                <div className="w-full">
                                    <form onSubmit={handleSubmit(submitFormCallback)} id="event-popup-form" className="grid gap-3 bg-white p-3 lg:p-4 rounded lg:shadow-lg w-full lg:pb-4 pb-24">
                                        <input type="hidden" name="id" value="undefined"/>

                                        {submitEvent.status === "error" ? <>
                                            <div className={"text-red-500 bg-red-50 border border-red-500 px-1 mx-1"}>
                                                <span className={"font-bold"}>Ошибка {submitEvent.error.error || ""}: </span>
                                                {submitEvent.error.message || JSON.stringify(submitEvent.error)}</div>
                                        </> : null}

                                        <div>
                                            <label className="font-sm text-blue-light bg-white px-1 relative top-2 left-3">
                                                Имя
                                            </label>
                                            <input className="rounded-sm px-5 border border-blue-light h-10 text-blue w-full"
                                                   placeholder="Ваше имя"
                                                   {...register("name", {
                                                       required: true,
                                                   })}
                                            />
                                            {errors.name ? <div className={"text-red-500"}>Введите имя</div> : null}
                                        </div>
                                        <div>
                                            <label className="font-sm text-blue-light bg-white px-1 relative top-2 left-3">
                                                Телефон
                                            </label>
                                            <input className="rounded-sm px-5 border border-blue-light h-10 text-blue w-full"
                                                   placeholder="Ваш телефон"
                                                   {...register("tel", {
                                                       required: true,
                                                       pattern: /^\+?[78][-(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/,
                                                   })}
                                            />
                                            {errors.phone ? <div className={"text-red-500"}>Введите телефон (например +79015552233)</div> : null}
                                        </div>
                                        <div>
                                            <label className="font-sm text-blue-light bg-white px-1 relative top-2 left-3">
                                                E-mail
                                            </label>
                                            <input className="rounded-sm px-5 border border-blue-light h-10 text-blue w-full"
                                                   placeholder="Ваш E-mail"
                                                   {...register("email", {
                                                       required: true,
                                                       // eslint-disable-next-line no-control-regex
                                                       pattern: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i,
                                                   })}
                                            />
                                            {errors.email ? <div className={"text-red-500"}>Введите e-mail (например vasya@mail.ru)</div> : null}

                                        </div>
                                        <div>
                                            <label className="font-sm text-blue-light bg-white px-1 relative top-2 left-3">
                                                Возраст
                                            </label>
                                            <input className="rounded-sm px-5 border border-blue-light h-10 text-blue w-full"
                                                   placeholder="Ваш возраст"
                                                   {...register("age", {
                                                       required: true,
                                                       pattern: /^(?:48|[1-9][0-9])$/,
                                                   })}
                                            />
                                            {errors.age ? <div className={"text-red-500"}>Введите возраст</div> : null}
                                        </div>
                                        <div>
                                            <input type="hidden"
                                                   {...register("g-recaptcha-response", {
                                                       // required: true,
                                                   })}
                                            />
                                            <Recaptcha
                                                sitekey={"6LdojQcTAAAAACuS2_zbjPhYcZUg464ExJiIyzN4"}
                                                hl={"ru"}
                                                verifyCallback={onRecaptchaVerifyCallback}
                                                expiredCallback={onRecaptchaExpiredCallback}
                                            />
                                            {errors['g-recaptcha-response'] ? <div className={"text-red-500"}>Введите капчу</div> : null}
                                        </div>

                                        <input type="hidden" name="partner" value="94e08050-3bb6-4987-8413-b0835cbb659f"/>
                                        <input type={"hidden"} {...register("event_id")}/>

                                        <div>
                                            <button
                                                className="py-3 w-full text-white bg-red rounded font-lg font-bold hover:bg-red-light active:bg-red-saturated hover:shadow-red-light active:bg-red-saturated disabled:opacity-50 js-submit-button"
                                                type={"submit"}
                                                id="submit-button">ЗАПИСАТЬСЯ
                                            </button>
                                        </div>
                                    </form>
                                    <div className="mt-8 hidden lg:flex">
                                        <span>*</span>
                                        <p className="ml-2">
                                            Оставляя заявку, Вы подтверждаете свое согласие с <a className="text-red" href="/terms_of_service">пользовательским соглашением</a> и<a className="text-red" href="/privacy_policy">политикой конфиденциальности.</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    </>);

}
