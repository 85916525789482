import fs from 'fs';
import path from 'path';


export const SeoConfig = [
    { url: "/express-svidaniya.php", name: " Экспресс свидания" },
    { url: "/speed-dating-otzyvi.php", name: "Speed dating отзывы" },
    { url: "/vecherinka-svidaniy.php", name: "Вечеринка свиданий" },
    { url: "/vecher-svidaniy.php", name: "Вечер свиданий" },
    { url: "/art-svidaniya.php", name: "Арт свидания" },
    { url: "/pyatiminutniesvidaniya.php", name: "Пятиминутные свидания" },
    { url: "/speed-dating.php", name: "Speed dating" },
    { url: "/speeddating.php", name: "Speeddating" },
    { url: "/speed-dating-v-moskve.php", name: "Speed dating в Москве" },
    { url: "/vecherinka-speed-dating.php", name: "Вечеринки Speed dating" },
    { url: "/speeddating.php", name: "Спиддейтинг" },
    { url: "/ruspeeddating.php", name: "Спиддатинг" },
    { url: "/speed-dating-v-restorane-mersi.php", name: "Speed dating в ресторане" },
    { url: "/speed-dating-svidaniya-znakomstva.php", name: "Speed dating свидания - знакомства от А до Я" },
    { url: "/bistrieznakomstva.php", name: "Быстрые знакомства" },
    { url: "/express-znakomstva.php", name: "Экспресс знакомства" },
    { url: "/klub-znakomstv.php", name: "Клуб знакомств" },
    { url: "/klub-serieznih-znakomstv.php", name: "Клуб серьезных знакомств" },
    { url: "/klub-znakomstv-v-moskve.php", name: "Клуб знакомств в Москве" },
    { url: "/cafe-znakomstv.php", name: "Кафе знакомств" },
    { url: "/vecher-znakomstv.php", name: "Вечер знакомств" },
    { url: "/vecherinka-znakomstv.php", name: "Вечеринка знакомств" },
    { url: "/dating-site.php", name: "Сайт знакомств" },
    { url: "/duet-club.php", name: "Клуб Дуэт Знакомств" },
    { url: "/r-gorod.php", name: "Клуб знакомств Романтический город" },
    { url: "/helloparty.php", name: "Клуб Helloparty" },
    { url: "/luckycity.php", name: "Клуб Luckycity" },
    { url: "/nsdating.php", name: "Вечер свиданий Nsdating" },
    { url: "/city-date.php", name: "City-date" },
    { url: "/fastlife.php", name: "Fastlife (Фастлайф)" },
    { url: "/nasvidanii.php", name: "Насвидании (Nasvidanii)" },
    { url: "/rend-vous.php", name: "Рандеву (Rend-vous)" },
];
export const pages = {

};
const seoRoute = (url, menuTitle, title, metadesc, metakw, template = null) => {
    if(fs && fs.readFileSync) {
    pages["/" + url + ".php"] = {
        menuTitle, title, metadesc, metakw, url,
            template: fs.readFileSync(path.resolve(__dirname, 'templates', `${url}.hbs`), "utf8"),
    };
    }
}

seoRoute(
    'express-svidaniya',
    ' Экспресс свидания',
    'Экспресс свидания в Москва | Заходите',
    'Экспресс свидания в Москве, анонсы, отзывы и фото.',
    'экспресс свидания, экспресс свидания Москва, экспресс свидания отзывы, экспресс свидания москва отзывы',
);
seoRoute(
    'speed-dating-otzyvi',
    'Speed dating отзывы',
    'Speed dating отзывы',
    '',
    '',
);
seoRoute(
    'vecherinka-svidaniy',
    'Вечеринка свиданий',
    'Вечеринка свиданий в Москве | Заходите',
    'Вечеринки быстрых свиданий в Москве, анонсы, отзывы и фото.',
    'вечеринка свиданий, вечеринка быстрых свиданий, вечеринка быстрых свиданий в Москве, вечеринка экспресс свидани',
);
seoRoute(
    'vecher-svidaniy',
    'Вечер свиданий',
    'Вечера быстрых свиданий | Москва | Заходите',
    'Вечера быстрых свиданий в Москве. Контингент вечера свиданий - высшей категории. Рассылаем симпатии быстрых свиданий в тот же день',
    'вечер свиданий, вечер быстрых свиданий, вечер быстрых свиданий Москва, 10-15 свиданий за вечер в Москве, вечер быстрых свиданий отзывы, клуб знакомств Вечер свиданий, вечер свиданий ДавайНаСвидание, вечер быстрых свиданий кому за',
);
seoRoute(
    'art-svidaniya',
    'Арт свидания',
    'АРТ СВИДАНИЯ - Узнайте это',
    'Арт свидания - уникальная возможность провести время, узнать многое интересное об искусстве, написать собственную работу и, главное, найти свою вторую половинку.',
    'арт быстрые свидания, арт speed dating, пастель, акварель, знакомства',
);
seoRoute(
    'pyatiminutniesvidaniya',
    'Пятиминутные свидания',
    '5-ти минутные свидания здесь | Заходите',
    'Пятиминутные свидания позволяют вам познакомиться сразу с большим количеством человек в Москве эффективно. Блицсвидания крайне распространены в Москве.',
    'пятиминутные свидания, свидания по 5 минут, 5 минутные свидания, свидания пятиминутки, свидание за 5 минут в москве, свидание за 7 минут, пятиминутные свидания в москве',
);
seoRoute(
    'speed-dating',
    'Speed dating',
    'Speed dating #1 | Заходите',
    'Speed dating в будни и выходные. Контингент высшей категории. 13-20 пар. Рассылаем симпатии speed dating в тот же день',
    'speed dating, speed datings, speed date, speed dates',
);
seoRoute(
    'speeddating',
    'Speeddating',
    'SpeedDating №1 | Москва | Заходите',
    'Moscow speeddating в Москве от компании ДавайНаСвидание: лучший контингент, взаимные симпатии speeddating в тот же день с фото:',
    'speeddating, moscowspeeddating, speeddating Москва, speeddating ресторан',
);
seoRoute(
    'speed-dating-v-moskve',
    'Speed dating в Москве',
    'Speed dating Москва | Ежедневно | Заходите',
    'История Speed dating в Москве от компании ДавайНаСвидание отзывы и запись. Заходите узнать отзывы о speed dating',
    'speed dating в москве отзывы',
);
seoRoute(
    'vecherinka-speed-dating',
    'Вечеринки Speed dating',
    'Вечеринки speed dating ежедневно | Заходите',
    'Вечеринки speed dating в Москве. Контингент высшей категории. Рассылаем симпатии вечеринок speed dating в тот же день',
    'Вечеринка speed dating, Интересные факты о вечеринках speed dating, Вечеринка знакомств speed dating, Вечеринка speed dating Москва, Вечеринки быстрые знакомства speed dating, Вечеринка быстрые знакомства speed dating в Москве, speed dating hello party вечеринки',
);
seoRoute( // FIXME cyrillic url
    'speeddating',
    'Спиддейтинг',
    'Спиддейтинг отзывы | Москва | Заходите',
    'Спиддейтинг в Москве от компании ДавайНаСвидание. Лучший контингент, взаимные симпатии спиддейтинге в тот же день с фото. Отзывы здесь',
    'спиддейтинг, спиддейтинг москва, спиддейтинг отзывы, спиддейтинг москва отзывы',
);
seoRoute(
    'ruspeeddating',
    'Спиддатинг',
    'Спиддайтинг вечеринка | Москва | Заходите',
    'Вечеринки спиддатинг в Москве от компании ДавайНаСвидание. Лучший контингент, взаимные симпатии спиддайтинга в тот же день с фото. Отзывы здесь',
    'спиддатинг, спиддатинг москва, спиддатинг вечеринка',
);
seoRoute(
    'speed-dating-v-restorane-mersi',
    'Speed dating в ресторане',
    'Вечеринка speed dating в ресторане Мерси',
    'Speed dating в ресторане Мерси проходит в очень привлекательно и уютной обстановки. Авторская кухня и интерьер позволит расслабиться и окунуться в потрясающее романтическое путешествие',
    'вечеринка speed dating в ресторане Мерси',
);
seoRoute(
    'speed-dating-svidaniya-znakomstva',
    'Speed dating свидания - знакомства от А до Я',
    'Speed dating свидания - знакомства | Интересные факты',
    'Speed dating свидания и знакомства в будни и выходные №1 от ООО ДавайНаСвидание. Узнайте',
    'speed dating знакомства, speed dating свидания',
);
seoRoute(
    'bistrieznakomstva',
    'Быстрые знакомства',
    'Быстрые знакомства в Москве | Заходите',
    'Быстрые знакомства в Москве является самым лучшим способом знакомств. Чтобы убедиться в этом, достаточно прочитать отзывы о сервисе быстрых знакомствах здесь',
    'быстрые знакомства, быстрые знакомства москва, быстрые знакомства отзывы, сайт быстрые знакомства, сервис быстрых знакомств, быстрые знакомства speed dating, быстрые знакомства в кафе, быстрые знакомства в москве, быстрые знакомства в москве отзывы, вечеринка быстрых знакомств, клуб быстрых знакомств, быстрые знакомства с номерами телефонов, вечер быстрых знакомств',
);
seoRoute(
    'express-znakomstva',
    'Экспресс знакомства',
    'Экспресс знакомства | Москва | Заходите',
    'Вечеринки экспресс знакомств в Москве с номерами телефонов и отзывы о компании ДавайНаСвидание. Узнайте важную информацию.',
    'Экспресс знакомства, экспресс знакомства с номерами телефонов, Клуб экспресс знакомств, Экспресс знакомства в Москве, Вечеринки экспресс знакомств, Экспресс знакомства отзывы, Кафе экспресс знакомств',
);
seoRoute(
    'klub-znakomstv',
    'Клуб знакомств',
    'Клуб знакомств | Полезная информация | Заходите',
    'Клуб знакомств (классика отношений) ДавайНаСвидание. Отзывы здесь! Узнайте важную информацию.',
    'Клуб знакомств, клуб знакомств отзывы, клуб знакомств классика, классика отношений клуб знакомств, классика клуб знакомств отзывы, клуб знакомств по интересам, клуб арт-знакомств ДавайНаСвидание, клуб знакомств и дегустаций ДавайНаСвидание, клуб знакомств для мужчин, Клуб знакомств кому за 30, Клуб знакомств кому за 40, клуб знакомств кому за 45',
);
seoRoute(
    'klub-serieznih-znakomstv',
    'Клуб серьезных знакомств',
    'Клуб серьезных знакомств | Полезная информация',
    'Клуб серьезных знакомств для отношений на базе реальных знакомств ДавайНаСвидание. Узнайте важную информацию.',
    'клуб серьезных знакомств, клуб знакомств для серьезных отношений, клуб реальных знакомств, найти клуб знакомств, клуб быстрых знакомств, клуб знакомств ДавайНаСвидание, клуб знакомств любовь и голуби, клуб знакомств дуэт ближайшие мероприятия, седьмое небо клуб знакомств, клуб знакомств двое, клуб знакомств mail ru, клуб экспресс знакомств',
);
seoRoute(
    'klub-znakomstv-v-moskve',
    'Клуб знакомств в Москве',
    'Клуб знакомств в Москве информация и отзывы | Заходите',
    'Клуб знакомств в Москве информация и отзывы, элитный (вип) клуб знакомств и по интересам. Узнайте важную информацию.',
    'Клуб знакомств в москве, московский клуб знакомств, кривоколенный переулок 14 клуб знакомств, страстной бульвар 11 клуб знакомств, клуб знакомств на чистых прудах, клуб знакомств на чеховской, клубы знакомств по интересам москва, клуб знакомств московский дворик, клубы знакомств в москве отзывы, клуб элитных знакомств, клуб вип знакомств, элитный клуб знакомств в москве',
);
seoRoute(
    'cafe-znakomstv',
    'Кафе знакомств',
    'Кафе знакомств в Москве | Заходите',
    'Кафе быстрых знакомств ДавайНаСвидание работает для тех, кто хочет познакомиться и найти свою половинку',
    'кафе знакомств, кафе быстрых знакомств, кафе быстрых знакомств Москва, кафе знакомств Москва пересаживаются, кафе клуб знакомства, кафе экспресс знакомств',
);
seoRoute(
    'vecher-znakomstv',
    'Вечер знакомств',
    'Вечер знакомств в Москве с Пн-Вскр | Заходите',
    'Мы организуем вечера знакомств в Москве, самый действенный способ познакомиться и найти свою вторую половинку',
    'вечер знакомств, вечера знакомств Москва, вечер быстрых знакомств, вечер знакомств кому за 30, вечер знакомств кому за 40, Вечера знакомств 50',
);
seoRoute(
    'vecherinka-znakomstv',
    'Вечеринка знакомств',
    'Вечеринка знакомств в Москве с Пн по Вскр | Заходите',
    'Вечеринка знакомств в Москве сегодня от компании ДавайНаСвидание. Интересное предложение на вечеринку. СКИДКА - Узнавайте.',
    'вечеринка знакомств, вечеринка знакомств москва, вечеринка знакомств кому за 30 40, вечеринка быстрых знакомств, вечеринка знакомств speed dating, вечеринка знакомств отзывы',
);
seoRoute(
    'dating-site',
    'Сайт знакомств',
    'Знакомства speed dating! Рекомендуем прочитать',
    'Сравнение быстрые свидания (speed dating) с сайтом знакомств',
    'знакомства, сайт знакомств, znakomstva, знакомства speed dating',
);
seoRoute(
    'duet-club',
    'Клуб Дуэт Знакомств',
    'Клуб Дуэт Знакомств | duet-club | Заходите!',
    'Клуб Дуэт знакомств. www.duet-club.ru. Заходите',
    'www duet club ru клуб знакомств дуэт москва отзывы',
);
seoRoute(
    'r-gorod',
    'Клуб знакомств Романтический город',
    'Клуб знакомств Романтический Город | R-GOROD',
    'Клуб знакомств Романтический Город (www.r-gorod.ru). Узнавайте',
    'www  r-gorod ru клуб Романтический Город',
);
seoRoute(
    'helloparty',
    'Клуб Helloparty',
    'Hello Party | Helloparty| Заходите',
    'Клуб знакомств Hello party. www.helloparty.ru. Заходите',
    'www hello party ru helloparty',
);
seoRoute(
    'luckycity',
    'Клуб Luckycity',
    'Клуб знакомств Luckycity| LuckyCity | Заходите!',
    'Клуб знакомств Luckycity. www.luckycity.ru. Заходите',
    'www luckycity ru клуб лаки сити lucky city',
);
seoRoute(
    'nsdating',
    'Вечер свиданий Nsdating',
    'Нсдэйтинг вечер свиданий | Nsdating | Заходите',
    'Клуб вечер свиданий Nsdating. www.nsdating.ru. Заходите',
    'www nsdating ru клуб клуб вечер свиданий отзывы',
);
seoRoute(
    'city-date',
    'City-date',
    'City date Speed dating в Москве | city-date | Заходите',
    'City date speed dating интересные и познавательные вечеринки. www.city-date.ru. Заходите',
    'www city date ru москва отзывы',
);
seoRoute(
    'fastlife',
    'Fastlife (Фастлайф)',
    'Fastlife (Фастлайф) speed dating - быстрые свидания | Заходите',
    'Fastlife (Фастлайф) speed dating - компания по организации быстрых свиданий в Москве. www.fastlife.su. Заходите',
    'www fastlife su ru speed dating быстрые свидания москва отзывы',
);
seoRoute(
    'nasvidanii',
    'Насвидании (Nasvidanii)',
    'Nasvidanii быстрые свидания | Насвидании | Заходите',
    'Клуб быстрых свиданий Nasvidanii. www.nasvidanii.ru. Заходите',
    'www nasvidanii ru быстрые свидания москва отзывы',
);
seoRoute(
    'rend-vous',
    'Рандеву (Rend-vous)',
    'Рандеву Speed dating | Rend-Vous | Заходите!',
    'Клуб Рандеву. www.rend-vous.ru. Заходите',
    'www rend vous ru рандеву москва отзывы',
);

export default SeoConfig;
