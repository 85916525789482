import React, { useState } from "react";

export default function GroupSelector(props) {
    const ageGroups = props.ageGroups;

    const [ open, setOpen ] = useState(false);
    const [ selected, setSelected ] = useState(null);
    const onSelect = group => {
        if (group && group.href) {
            window.open(group.href);
            return false;
        }
        setOpen(false);
        setSelected(group);
        props.onSelectGroup(group);
    };

    return (<div className="select-component relative z-50 shadow-violet-sm xl:shadow-none">
        <div className="select-component__input bg-white px-4 py-3 flex xl:hidden items-center rounded relative cursor-pointer justify-between" onClick={() => setOpen(!open)}>

            {!selected ? (<slot name="input">Не выбрано</slot>) : (<>
                <span className="font-bold text-blue xl:ml-0 ml-2">{selected.title}</span>
                <span className="grid grid-cols-2 xl:mt-2 xl:ml-0 ml-auto">
                    <span className="flex items-center">
                        <i className="icon-female text-red"/>
                        <span className="text-red">{selected.range.female}</span>
                    </span>
                    <span className="flex items-center">
                        <i className="icon-male text-blue-light"/>
                        <span className="text-blue-light">{selected.range.male}</span>
                    </span>
                </span>
            </>)}

            <i className="icon-angle-down text-blue ml-2"/>
        </div>
        <div className={`select-component__options ${open ? "block" : "hidden"} xl:grid grid-cols-5 xl:static absolute w-full shadow-violet-sm xl:shadow-none gap-6`}>
            <div className="bg-white xl:hover:bg-white hover:bg-background1 px-4 py-3 xl:hidden flex items-center xl:rounded relative xl:active:shadow-violet-sm cursor-pointer select-component__option"
                 onClick={() => onSelect(null)}>
                <div slot="option">Не выбрано</div>
            </div>


            {ageGroups.map((group, id) => (<div key={id} className="bg-white xl:hover:bg-white hover:bg-background1 px-4 py-3 xl:block flex items-center xl:rounded relative xl:active:shadow-violet-sm cursor-pointer select-component__option"
                     onClick={() => onSelect(group)}>
                    <div slot="option">
                        <i className={`icon-mark xl:absolute text-${group.flagColor} -top-2 left-1`}/>
                        <span className="font-bold text-blue xl:ml-0 ml-2">{group.title}</span>
                        {!!(group.range) ? (
                            <span className="grid grid-cols-2 xl:mt-2 xl:ml-0 ml-auto">
                                <span className="flex items-center">
                                    <i className="icon-female text-red"/>
                                    <span className="text-red">{group.range.female}</span>
                                </span>
                                <span className="flex items-center">
                                    <i className="icon-male text-blue-light"/>
                                    <span className="text-blue-light">{group.range.male}</span>
                                </span>
                            </span>
                        ) : (
                            <a href={group.href} target={"_blank"} className="xl:mt-2 flex justify-center xl:ml-0 ml-auto xl:text-base text-sm">
                                <span className="text-red">{group.text}</span>
                            </a>
                        )}
                    </div>
                </div>))}


        </div>
    </div>);
}