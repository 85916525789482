import React  from 'react';
import { Helmet } from "react-helmet";

export default function ThankYou() {
    return (<>
        <Helmet>
            <title>Заявка принята - ДавайНаСвидание</title>
        </Helmet>
        <div className="bg-background1 pt-14 pb-20">
            <section>
                <div className="container mx-auto">
                    <div>
                        <h2 className="text-xl lg:text-2xl font-bold text-blue uppercase">ВАША ЗАЯВКА ОТПРАВЛЕНА</h2>
                        <h3 className="text-xl mt-4 text-red uppercase font-bold">БЛАГОДАРИМ ЗА УЧАСТИЕ в мероприятии!</h3>
                        <p className="mt-4 font-bold text-xl lg:text-base">
                            Вся необходимая информация отправлена на Вашу почту. Наши менеджеры свяжутся с Вами в течение 1 часа. По всем вопросам Вы можете обратиться по телефону: 8 (926) 636-20-46
                        </p>
                    </div>
                </div>
            </section>
        </div>
    </>);
}