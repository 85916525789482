import React from "react";
import { Helmet } from "react-helmet";
import store, { openPopupEvent, selectEvents } from "../store";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import qs from 'query-string';
import { PHOTOS_BASE_URL } from "../app.config";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

export default function Cafe() {
    const dispatch = useDispatch();

    const { alias: _alias } = useParams();
    let _match = _alias.match(/^(.*)\.html$/);
    let alias = null;
    if (_match) {
        alias = _match[1];
    }

    let eventId = null;
    const history = useHistory();
    const _query = qs.parse(history.location.search);
    eventId = typeof _query.eid !== 'undefined' ? (+_query.eid) : null;

    const events = useSelector(selectEvents);

    const event = events.find(event => (+event.id === +eventId && event.cafe_alias === alias));
    if ((alias === null) || (eventId === null) || (!event)) {
        return <>404</>;
    }

    SwiperCore.use([ Navigation ]);

    return (<>
        <Helmet>
            <meta name="keywords" content="быстрые свидания, быстрые свидания Москва, быстрые свидания отзывы"/>
            <meta name="description" content="Быстрые Свидания в Москве сегодня, возрастные группы. Симпатии в тот же день. Быстрые Свидания - СКИДКА 30%"/>
            <title>{event.cafe_name} | ДавайНаСвидание | Быстрые Cвидания в Москве</title>
        </Helmet>

        <section className="preview">
            <div className="flex bg-cover overflow-hidden relative bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${PHOTOS_BASE_URL}${event.cafe_photo})` }}>
                <div className="container mx-auto flex flex-col-reverse lg:flex-row">
                    <div className="w-full lg:w-5/12 lg:h-full lg:overflow-hidden flex justify-center lg:justify-end">
                        <div className="h-full lg:h-inherit w-screen absolute rounded-tl-md rounded-tr-md overflow-hidden">
                            <div className="bg-violet bg-opacity-25 h-full w-full backdrop-filter backdrop-blur-lg x-bicycle-backdrop"/>
                        </div>
                        <div className="lg:flex w-full justify-end lg:pr-12 py-4 lg:py-16">
                            <div className="max-w-full grid grid-cols-2 lg:block">
                                <p className="lg:text-lg font-bold text-white opacity-50 col-start-1 col-end-2 row-start-1 row-end-2">{event.__weekday}</p>
                                <p className="lg:text-lg font-bold text-white opacity-50 col-start-2 col-end-3 row-start-1 row-end-2 text-right lg:hidden block">
                                    Стоимость</p>
                                <p className="text-lg lg:text-xl font-bold text-white opacity-95 col-start-1 col-end-2 row-start-2 row-end-3">{event.__date_str}</p>
                                <p className="mt-6 lg:mt-12 text-lg lg:text-xl font-bold  text-white opacity-50 col-start-1 col-end-3 row-start-3 row-end-4">
                                    МЕСТО ПРОВЕДЕНИЯ </p>
                                <p className="text-xl lg:text-2xl font-extrabold text-white leading-tight opacity-95 col-start-1 col-end-3 row-start-4 row-end-5">{event.cafe_name}</p>
                                <div className="flex mt-8 lg:mt-20 opacity-95 col-start-1 col-end-3 row-start-5 row-end-6">
                                    <i className="icon-location text-white text-lg"/>
                                    <p className="ml-3 text-white font-bold lg:text-lg">
                                        {event.cafe_address}
                                    </p>
                                </div>
                                <div className="flex mt-0 lg:mt-2 opacity-95 col-start-1 col-end-3 row-start-6 row-end-7">
                                    <i className="icon-metro text-lg text-white"/>
                                    <p className="lg:text-lg text-white font-bold ml-3">
                                        {event.cafe_metro}
                                    </p>
                                </div>
                                <div className="text-white opacity-95 font-bold lg:font-extrabold text-lg lg:text-2xl mt-0 lg:mt-12 text-right lg:text-left col-start-2 col-end-3 row-start-2 row-end-3">{event.price}
                                    ₽
                                </div>
                                <div className="block lg:hidden col-start-1 col-end-3 row-start-7 row-end-8 mt-5">
                                    <button onClick={() => dispatch(openPopupEvent(event))}
                                            className="py-3 px-28 w-full text-white bg-red rounded font-lg font-bold block h-mincontent hover:bg-red-light active:bg-red-saturated hover:shadow-red-light opacity-95 active:bg-red-saturated"
                                    >
                                        Я иду
                                    </button>
                                </div>
                                <div className="bg-white relative rounded py-3 px-4 w-max mt-12 mb-0 hidden lg:block">
                                    <i className="icon-mark absolute text-red -top-2 left-1"/>
                                    <span className="uppercase text-blue font-bold">{event.age_group_text}</span>
                                    <span className="grid grid-cols-2 mt-2 gap-2">
                                        <span className="flex items-center">
                                            <i className="icon-female text-red"/>
                                            <span className="text-red">{event.min_age_f}-{event.max_age_f}</span>
                                        </span>
                                        <span className="flex items-center">
                                            <i className="icon-male text-blue-light"/>
                                            <span className="text-blue-light">{event.min_age_m}-{event.max_age_m}</span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-7/12 w-full h-full">
                        <div className="flex items-end lg:px-12 pt-40 pb-3 lg:py-16 lg:h-full w-full lg:justify-end">
                            <button onClick={() => dispatch(openPopupEvent(event))}
                                    className="hidden lg:block py-3 px-28 hidden lg:block text-white bg-red rounded font-lg font-bold block h-mincontent hover:bg-red-light active:bg-red-saturated hover:shadow-red-light active:bg-red-saturated"
                            >
                                Я иду
                            </button>
                            <div className="bg-white relative rounded py-3 px-4 w-max mt-12 mb-0 lg:hidden block">
                                <i className="icon-mark absolute text-red -top-2 left-1"/>
                                <span className="uppercase text-blue font-bold">{event.age_group_text}</span>
                                <span className="grid grid-cols-2 mt-2 gap-2">
                                <span className="flex items-center">
                                    <i className="icon-female text-red"/>
                                    <span className="text-red">{event.min_age_f}-{event.max_age_f}</span>
                                </span>
                                <span className="flex items-center">
                                    <i className="icon-male text-blue-light"/>
                                    <span className="text-blue-light">{event.min_age_m}-{event.max_age_m}</span>
                                </span>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="about bg-background1 relative">
            <div className="container mx-auto">
                <div className="py-16">
                    <h2 className="lg:text-2xl text-xl text-blue font-bold lg:leading-10 leading-5 uppercase">о мероприятии</h2>
                    <div className="lg:grid grid-cols-3 gap-32 mt-12">
                        <div className="col-start-1 col-end-3">
                            <h5 className="mt-4 text-lg">{event.description}</h5>
                        </div>
                        <div className="col-start-3 col-end-4 mt-12 lg:mt-0">
                            {event.info ? (
                                <ul>

                                    <li className="flex items-center">
                                        <p className="text-violet font-bold text-lg">{event.info}</p>
                                    </li>
                                    { /*                        <li class="flex mt-4 lg:mt-8 items-center">
                                <img src="/images/wine.svg" class="w-8 h-8">
                                <p class="ml-4 text-violet font-bold text-lg">Включен напиток</p>
                            </li>
                            <li class="flex items-center">
                                <img src="/images/shoe.svg" class="w-8 h-8">
                                <p class="ml-4 text-violet font-bold text-lg">{event.dopinfo}</p>
                            </li>
                            <li class="flex mt-4 lg:mt-8 items-center">
                                <img src="/images/record.svg" class="w-8 h-8">
                                <p class="ml-4 text-violet font-bold text-lg">DJ-дискотека</p>
                            </li>
                            <li class="flex mt-4 lg:mt-8 items-center">
                                <img src="/images/game.svg" class="w-8 h-8">
                                <p class="ml-4 text-violet font-bold text-lg">Настольные игры в конце вечера</p>
                            </li>
                            <li class="flex mt-4 lg:mt-8 items-center">
                                <img src="/images/jack-in-the-box.svg" class="w-8 h-8">
                                <p class="ml-4 text-violet font-bold text-lg">Развлекательная программа</p>
                            </li> */}
                                </ul>) : null}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="about relative z-30">
            <div className="container mx-auto">
                <div className="pt-16 pb-24">
                    <div className="lg:grid grid-cols-3 gap-32">
                        <div className="col-start-1 col-end-3">
                            <h2 className="lg:text-2xl text-xl text-blue font-bold lg:leading-10 leading-5 uppercase">Подробнее</h2>
                            <h5 className="mt-4 text-lg">
                                {event.cafe_description.trim().split("</p><p>").filter(p => p !== "").map((p, id) => <p key={id}>{p.replace(/(<([^>]+)>)/gi, "")}</p>)}
                            </h5>
                        </div>
                        <div className="col-start-3 col-end-4 mt-12 lg:mt-0">
                            <h3 className="text-red text-lg font-bold uppercase">Как добраться</h3>
                            <h5 className="mt-4 text-lg">
                                {event.cafe_how_to_pass.trim().split("</p><p>").filter(p => p !== "").map((p, id) => <p key={id}>{p.replace(/(<([^>]+)>)/gi, "")}</p>)}
                            </h5>
                            <a href="#map" className="flex mt-8 items-center text-blue text-lg opacity-50 font-bold">
                                <i className="icon-position"/>
                                <span className="ml-2">На карте</span>
                            </a>
                            <button onClick={() => dispatch(openPopupEvent(event))}
                                    className="py-3 w-full md:w-auto mt-8 px-16 text-white bg-red rounded font-lg font-bold block h-mincontent
                                    hover:bg-red-light active:bg-red-saturated hover:shadow-red-light active:bg-red-saturated"
                            >
                                ЗАПИСАТЬСЯ
                            </button>
                        </div>
                    </div>
                    <div className="mt-16">
                        <div id="cafe-swiper" className="w-full h-full relative overflow-hidden">
                            <div className="swiper-button-next flex justify-center items-center w-10 h-10 rounded-full bg-white shadow-violet-sm">
                                <i className="icon-right-open text-blue-light"/>
                            </div>
                            <div className="swiper-button-prev flex justify-center items-center w-10 h-10 rounded-full bg-white shadow-violet-sm">
                                <i className="icon-left-open text-blue-light"/>
                            </div>

                            {event.cafe_photos.length >= 2 ? (
                                <Swiper className="w-full h-full relative overflow-hidden"
                                        spaceBetween={30}
                                        loop={true}
                                        slidesPerView={1}
                                        navigation={true}
                                        breakpoints={{
                                            1100: {
                                                slidesPerView: 2,
                                            },
                                        }}
                                        pagination={{ clickable: true }}>
                                    {event.cafe_photos.map((photo, id) => (
                                        <SwiperSlide className="flex items-center justify-center" key={id}>
                                            <div className="flex rounded h-inherit">
                                                <img src={PHOTOS_BASE_URL + photo} alt="" className="object-contain w-full h-64 lg:h-96"/>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            ) : null}

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="map" className="bg-background1">
            <style>{".css-map iframe { height:500px; width: 100% }"}</style>

            <div className="container mx-auto css-map">
                <div className="pt-16 pb-24 w-full" dangerouslySetInnerHTML={{ __html: event.cafe_map }}/>
            </div>
        </section>

    </>);
}

Cafe.preInitStore = async (/*store*/) => {

};
