import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callMe, selectCallMeRequest } from "../store";
import { useForm } from "react-hook-form";


export default function TopIndexForm() {
    const dispatch = useDispatch();
    const callMeRequest = useSelector(selectCallMeRequest);

    // Form - start
    const { register, handleSubmit, setValue, trigger, formState: { errors } } = useForm();

    const submitFormCallback = (data) => {
        dispatch(callMe(data));
    };

    // Set redirect to thank you page
    useEffect(() => {
        if(callMeRequest.status === "done") {
            document.location.href = "/thankyou.php"
        }
    }, [callMeRequest])
    // Form - end

    return (<form className={"flex flex-col gap-4 lg:h-96"} onSubmit={handleSubmit(submitFormCallback)}>
        <div className="lg:block mt-72 lg:mt-0 bg-white p-4 rounded">
            <div className={"text-blue"}>Оставьте телефон, и мы Вам перезвоним</div>
            <div className="grid gap-3">
                <div>
                    <label className="font-sm text-blue-light bg-white px-1 relative top-2 left-3">
                        Телефон
                    </label>
                    <input className="rounded-sm px-5 border border-blue-light h-10 text-blue w-full" placeholder="Ваш телефон" {...register("tel", {
                        required: true,
                        pattern: /^\+?[78][-(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/,
                    })}/>
                    {errors.tel ? <div className={"text-red-500"}>Введите телефон (например +79015552233)</div> : null}
                </div>
            </div>
        </div>


        {(callMeRequest.status === "idle") || (callMeRequest.status === "error") ? <>
            <button className="py-3 px-20 w-full md:w-auto text-white bg-red rounded font-lg font-bold hover:bg-red-light active:bg-red-saturated hover:shadow-red-light active:bg-red-saturated"
                    type={"submit"}>ПЕРЕЗВОНИТЕ
            </button>
        </> : null }

        {callMeRequest.status === "done" ? <>
            <button className="lg:mt-0 mt-72 py-3 px-20 w-full md:w-auto text-white bg-red rounded font-lg font-bold hover:bg-red-light active:bg-red-saturated hover:shadow-red-light active:bg-red-saturated"
                    disabled={true}>СПАСИБО, МЫ ПЕРЕЗВОНИМ
            </button>
        </> : null}

    </form>);
}
