import React, { useState } from "react";
import { Helmet } from "react-helmet";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import TopIndexForm from "../components/TopIndexForm";
import { useDispatch, useSelector } from "react-redux";
import { openPopupEvent, selectEvents } from "../store";
import EventCard from "../components/EventCard";
import GroupSelector from "../components/GroupSelector";
import AccordeonItem from "../components/AccordeonItem";

export default function Index() {

    const eventList = useSelector(selectEvents);
    const dispatch = useDispatch();

    SwiperCore.use([ Navigation ]);

    const ageGroups = [
        {
            title: 'МОЛОДАЯ ГРУППА',
            range: {
                female: '18-28',
                male: '24-31',
            },
            flagColor: 'red',
            id: 1,
        },
        {
            title: 'ОСНОВНАЯ ГРУППА',
            range: {
                female: '24-36',
                male: '27-41',
            },
            flagColor: 'blue',
            id: 2,
        },
        {
            title: 'СТАРШАЯ ГРУППА',
            range: {
                female: '30-43',
                male: '33-48',
            },
            flagColor: 'lightblue',
            id: 3,

        },
        {
            title: 'ГРУППА 45+',
            range: {
                female: '44-60',
                male: '45-63',
            },
            flagColor: 'yellow',
            id: 4,
        },
        {
            title: 'VIP-МЕРОПРИЯТИЯ',
            range: {
                female: '18-99',
                male: '18-99',
            },
            flagColor: 'yellow',
            id: 5,
            // title: 'VIP-МЕРОПРИЯТИЯ',
            // text: 'Брачное агенство',
            // flagColor: 'violet',
            // href: "https://marriedandhappy.ru",
        },
    ];

    const faq = [
        {
            title: 'КАК ПРОХОДЯТ БЫСТРЫЕ СВИДАНИЯ В МОСКВЕ?',
            text:
                'Наиболее полный ответ можно получить в соответствующем <a href="/#description" class="text-red">разделе нашего сайта</a>.<br>' +
                '<br>' +
                'Вечеринка быстрых свиданий (speed dating) представляет собой 12-15 знакомств по 6-10 минут каждое. Именно такое количество пар и время на общение являются оптимальными, исходя из отзывов наших гостей. После каждого свидания звучит сигнал, и мужчины пересаживаются за следующий столик.<br>' +
                '<br>' +
                'Таким образом, за вечер каждый мужчина познакомится с каждой женщиной и каждая женщина познакомится с каждым мужчиной. Карта взаимных симпатий, заполненная каждым участником, помогает нам определить взаимные симпатии и направить контакты понравившихся людей друг-другу.<br>' +
                '<br>' +
                'Результаты вечера можно узнать в личном кабинете на нашем сайте. Все мероприятия проходят в изысканных заведениях Москвы, и это очень важно для наших гостей.<br>',
        },
        {
            title: 'ЗАЧЕМ ЛЮДИ ПРИХОДЯТ НА БЫСТРЫЕ СВИДАНИЯ?',
            text:
                'Первая и основная причина посещения быстрых знакомств - поиск второй половинки. Так говорят около 50% участников.<br>' +
                '<br>' +
                'Тем не менее, это не единственная причина, по которой вы можете стать участником подобного мероприятия. <a class="text-red" href="/#stories">Истории участников</a><br>' +
                '<br>' +
                'Со слов наших гостей, после нескольких свиданий многие чувствуют прилив сил и еще несколько дней находятся в приподнятом расположении духа.<br>' +
                '<br>' +
                'Еще одна причина попробовать быстрые знакомства - "нетворкинг", или, говоря по-русски, поиск новых контактов, для расширения возможностей. Все мы живем в социуме, и нуждаемся в деловых связях, в новой информации и знаниях. Именно <b>быстрые знакомства</b> дадут вам возможность найти свою половинку, а также хорошо провести время в кругу ярких и веселых людей.<br>',
        },
        {
            title: 'КАКОЙ КОНТИНГЕНТ ПРИХОДИТ НА БЫСТРЫЕ СВИДАНИЯ?',
            text:
                'Быстрые свидания - это мероприятия для уверенных в себе мужчин и женщин. Вы можете посмотреть фотографии участников здесь<br>' +
                '<br>' +
                'Можно сказать, что наши посетители состоявшиеся в жизни люди. Их выбор прийти на быстрые свидания зачастую связан с желанием сменить круг общения и найти свою половинку. По профессиям это: топ менеджеры и менеджеры среднего звена, врачи, режиссеры, предприниматели, инженеры, ученые и др.<br>',
        },
        {
            title: 'МОЖНО ЛИ НА БЫСТРЫХ СВИДАНИЯХ НАЙТИ СЕРЬЕЗНЫЕ ОТНОШЕНИЯ?',
            text:
                'Да! Девушки и мужчины, которые приходят, находятся поиска своей второй половинки. Именно поэтому, мероприятия так популярны и эффективны при поиске партнера для серьезных отношений. Посмотрите нашу статистику.<br>' +
                '<br>' +
                'На самом деле, мужчины и девушки, нацелены на серьезные отношения, так как отношения являются для женщин большим приоритетом, а для состоявшего мужчины хорошим "двигателем". В виду всего этого, можем с уверенностью заявлять, что на наших мероприятиях вы имеете все шансы найти свою половинку.<br>',
        },
        {
            title: 'ЧЕМ БЫСТРЫЕ СВИДАНИЯ ОТЛИЧАЮТСЯ ОТ САЙТОВ ЗНАКОМСТВ?',
            text:
                'Быстрые знакомства в значительной мере отличаются от сайтов знакомств. Самое важное отличие <em>speed dating</em> - это живое общение! Только здесь каждый собеседник видит другого человека. При живом общении каждый человек может раскрыться с лучшей стороны. Действительно, как говорится: "встречают по одежке, провожают по уму". Очень часто участник наших мероприятий - покорял девушку своими достижениями, умом.<br>' +
                '<br>' +
                'На быстрых знакомствах нельзя спрятаться за фотографией с photoshop\'ом или выдать себя за того, кем не являешься. Нельзя забывать и о том, что очень сложно представить, что думают онлайн участники сайтов знакомств и какие их истинные цели. Всем очевидно, после того, как люди начали онлайн-общение на сайте знакомств, их встреча может быть сильнейшим разочарованием, потому что их ожидания не соответствует действительности. Именно на быстрых свиданиях Вы в значительной степени защищены от подобных ситуаций.<br>',
        },
    ];


    const [ groupFilter, setGroupFilter ] = useState(null);
    const eventsToList = eventList.filter(event => ((!groupFilter) || (+groupFilter.id === +event.age_group)));

    return (<>
        <Helmet>
            <meta name="keywords" content="быстрые свидания, быстрые свидания Москва, быстрые свидания отзывы"/>
            <meta name="description" content="Быстрые Свидания в Москве сегодня, возрастные группы. Симпатии в тот же день. Быстрые Свидания - СКИДКА 30%"/>
            <title>ДавайНаСвидание | Быстрые Cвидания в Москве</title>
        </Helmet>

        <section id="main">
            <div className="bg-violet relative overflow-hidden">
                <div className="absolute bottom-0 lg:-bottom-16 -bottom-8 h-full -left-72
                sm:left-0 sm:right-0 lg:right-24 lg:h-auto w-full">
                    <img className="object-cover max-w-none lg:max-w-full h-4/6 sm:h-4/5 md:h-full lg:h-auto absolute bottom-0 lg:static" src="images/uploads/main-bg.png" alt=""/>
                </div>
                <div className="container mx-auto">
                    <div className="d-flex relative z-10">
                        <div className="pt-5 pb-5">
                            <div className="lg:flex justify-between">
                                <div className="flex flex-col justify-between">
                                    <h1 className="lg:text-3xl text-2xl font-extrabold leading-none uppercase">
                                        <span className="text-yellow">Быстрые свидания</span><br/>
                                        <span className="text-white">Speed Dating<br/> в Москве</span>
                                    </h1>

                                    <ul className="lg:block lg:my-16 hidden">
                                        <li className="flex items-center">
                                            <img src="images/burning-heart.svg" alt=""/>
                                            <span className="text-white font-bold ml-2">Разные возрастные группы</span>
                                        </li>
                                        <li className="mt-5 flex items-center">
                                            <img src="images/rotating-heart.svg" alt=""/>
                                            <span className="text-white font-bold ml-2">15-20 знакомств за вечер</span>
                                        </li>
                                        <li className="mt-5 flex items-center">
                                            <img src="images/burning-heart.svg" alt=""/>
                                            <span className="text-white font-bold ml-2">Развлекательная программа</span>
                                        </li>
                                        <li className="mt-5 flex items-center">
                                            <img src="images/rotating-heart.svg" alt=""/>
                                            <span className="text-white font-bold ml-2">Индивидуальный подбор пары</span>
                                        </li>
                                        <li className="mt-5 flex items-center">
                                            <img src="images/burning-heart.svg" alt=""/>
                                            <span className="text-white font-bold ml-2">Стоимость участия от 699 ₽</span>
                                        </li>
                                    </ul>

                                    <div className="lg:flex hidden">
                                        <a href="https://vk.com/marriedandhappymoscow" className="mr-2 flex">
                                            <i className="icon-vk text-white text-2xl flex h-mincontent"/>
                                        </a>
                                        <a href="https://t.me/marriedandhapp" className="flex">
                                            <img src="/images/tg-icon.svg" className="flex w-12 h-12" alt={""}/>
                                        </a>
                                    </div>
                                </div>
                                <div className="lg:grid flex grid-cols-1 gap-4 lg:w-80">
                                    <TopIndexForm/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="characteristics lg:hidden bg-background1">
            <div className="container mx-auto">
                <div className="py-8">
                    <ul>
                        <li className="flex items-center">
                            <img src="images/burning-heart.svg" alt=""/>
                            <span className="font-bold ml-2">Разные возрастные группы</span>
                        </li>
                        <li className="mt-5 flex items-center">
                            <img src="images/rotating-heart.svg" alt=""/>
                            <span className="font-bold ml-2">15-20 знакомств за вечер</span>
                        </li>
                        <li className="mt-5 flex items-center">
                            <img src="images/burning-heart.svg" alt=""/>
                            <span className="font-bold ml-2">Развлекательная программа</span>
                        </li>
                        <li className="mt-5 flex items-center">
                            <img src="images/rotating-heart.svg" alt=""/>
                            <span className="font-bold ml-2">Индивидуальный подбор пары</span>
                        </li>
                        <li className="mt-5 flex items-center">
                            <img src="images/burning-heart.svg" alt=""/>
                            <span className="font-bold ml-2">Стоимость участия от 699 ₽</span>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="announce" id="announce">
            <div className="container mx-auto">
                <div className="py-8 lg:pt-24 lg:pb-16">
                    <h2 className="lg:text-2xl text-xl text-blue font-bold lg:leading-10 leading-5">Анонс<br/> быстрых свиданий</h2>
                    <p className="mt-8 lg:mt-12 text-base lg:text-xl">Выберите вашу возрастную группу</p>
                    <div className="mt-3 lg:mt-16">
                        <GroupSelector ageGroups={ageGroups} onSelectGroup={setGroupFilter}/>
                    </div>
                    <div className="events grid grid-cols-1 lg:grid-cols-2 mt-8 gap-6">
                        {eventsToList.length ? eventsToList.map(evt => <EventCard key={evt.id} event={evt}/>) : (
                            <div className={"mt-8 lg:col-span-2 lg:mt-12 text-base lg:text-xl"}>
                                <div>В данный момент мы не планируем мероприятий для выбранной возрастной группы.</div>
                                <div>Но Вы можете попробовать записаться в другую группу, либо зайти позже.</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
        <section className="media bg-background1" id="media">
            <div className="container mx-auto">
                <div className="py-8 lg:pt-28 lg:pb-44">
                    <h2 className="lg:text-2xl text-xl text-blue font-bold lg:leading-10 leading-5">МЫ В СМИ</h2>
                    <div className="mt-28">
                        <Swiper className="w-full h-full relative overflow-hidden"
                                spaceBetween={30}
                                loop={true}
                                slidesPerView={1}
                                navigation={{
                                    prevEl: ".swiper-media-prev",
                                    nextEl: ".swiper-media-next",
                                }}
                                breakpoints={{
                                    1100: {
                                        slidesPerView: 2,
                                    },
                                }}
                                pagination={{ clickable: true }}>
                            <SwiperSlide>
                                <div className="w-full h-full">
                                    <video preload="none" poster="../images/uploads/dnsv_converted.png" controls={true} className="w-full h-full">
                                        <source src="../images/uploads/dnsv_converted.webm" type="video/webm"/>
                                        <source src="../images/uploads/dnsv_converted.mp4" type="video/mp4"/>
                                        <source src="../images/uploads/dnsv_converted.ogv" type="video/ogv"/>
                                    </video>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="w-full h-full">
                                    <video preload="none" poster="../images/uploads/lifenews_converted.png" controls={true} className="w-full h-full">
                                        <source src="../images/uploads/lifenews_converted.webm" type="video/webm"/>
                                        <source src="../images/uploads/lifenews_converted.mp4" type="video/mp4"/>
                                        <source src="../images/uploads/lifenews_converted.ogv" type="video/ogv"/>
                                    </video>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="w-full h-full">
                                    <video preload="none" poster="../images/uploads/yulya-1-kanal-2-poster.png" controls={true} className="w-full h-full">
                                        <source src="../images/uploads/yulya-1-kanal-2.mp4" type="video/mp4"/>
                                    </video>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="w-full h-full">
                                    <video preload="none" poster="../images/uploads/yulya-1-kanal-poster.jpg" controls={true} className="w-full h-full">
                                        <source src="../images/uploads/yulya-1-kanal-720p.mp4" type="video/mp4"/>
                                    </video>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="w-full h-full">
                                    <video preload="none" poster="../images/uploads/rbk_converted.jpg" controls={true} className=" w-full h-full">
                                        <source src="../images/uploads/rbk_converted.mp4" type=" video/mp4"/>
                                    </video>
                                </div>
                            </SwiperSlide>

                            <div className="swiper-pagination -mb-8"></div>
                            <div className="swiper-button-next swiper-media-next flex justify-center items-center w-10 h-10 rounded-full bg-white shadow-violet-sm">
                                <i className="icon-right-open text-blue-light"></i>
                            </div>
                            <div className="swiper-button-prev swiper-media-prev flex justify-center items-center w-10 h-10 rounded-full bg-white shadow-violet-sm">
                                <i className="icon-left-open text-blue-light"></i>
                            </div>
                        </Swiper>

                    </div>
                </div>
            </div>
        </section>
        <section className="social bg-violet">
            <div className="container mx-auto">
                <div className="pt-10 lg:pt-36 pb-96 lg:pb-40 relative">
                    <img src="images/social.svg" alt="" className="absolute sm:-right-4 bottom-4 lg:bottom-auto lg:top-32"/>
                    <div className="relative z-10">
                        <h2 className="lg:text-2xl text-xl text-white font-bold lg:leading-10 leading-5 uppercase">МЫ В СОЦиальных сетях</h2>

                        <p className="text-white lg:text-xl mt-8">
                            Добавляйтесь в наши группы Вконтакте.<br/>
                            Общайтесь находите новые знакомства.
                        </p>
                        <div className="flex mt-5 lg:mt-20">
                            <a href="https://vk.com/marriedandhappymoscow" className="mr-2 flex">
                                <i className="icon-vk text-white text-2xl flex h-mincontent"></i>
                            </a>
                            <div className="flex items-center">
                                <a href="https://t.me/marriedandhapp" className="flex">
                                    <img src="/images/tg-icon.svg" className="flex w-12 h-12"/>
                                </a>
                                <span className="text-white ml-3 hidden lg:block">
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="parallax">
            <div className="lg:bg-fixed bg-local h-screen parallax__image bg-cover" style={{ 'backgroundImage': 'url(images/parallax.png)' }}/>
        </section>

        <section className="stories" id="stories">
            <div className="container mx-auto">
                <div className="py-8 lg:pt-24 lg:pb-24">
                    <h2 className="lg:text-2xl text-xl text-blue font-bold lg:leading-10 leading-5 uppercase">ИСТОРИИ ЗНАКОМСТВ</h2>

                    <div className="mt-8 lg:mt-16">
                        <Swiper className="w-full h-full relative overflow-hidden"
                                spaceBetween={30}
                                loop={true}
                                slidesPerView={1}
                                autoHeight={true}
                                navigation={{
                                    prevEl: ".swiper-button-prev-pairs",
                                    nextEl: ".swiper-button-next-pairs",
                                }}
                                pagination={{ clickable: true }}
                        >

                                <SwiperSlide className="swiper-slide flex items-center justify-center">
                                    <div className="w-full h-full">
                                        <div className="relative flex-full lg:flex justify-end px-8">
                                            <div className="flex justify-center items-start lg:w-6/12 px-8">
                                                <img src="../images/uploads/dmitry_tatyana.jpg" alt="" className="object-cover relative w-full h-auto top-0 bottom-0"/>
                                            </div>
                                            <div className="lg:w-6/12 flex flex-col justify-start">
                                                <p className="text-blue font-semibold opacity-50">июль 2018 года</p>
                                                <p className="text-xl font-bold">
                                                    <span className="text-blue">Дмитрий</span>
                                                    <span className="text-blue opacity-50">&</span>
                                                    <span className="text-red">Татьяна</span>
                                                </p>
                                                <h5 className="text-lg mt-8">
                                                    Хотим поделиться своей историей знакомства, которая произошла благодаря быстрому знакомству 27.04.2018 19:00 в клубе ShishasHappyBar. Никогда не думал, что можно найти любовь так
                                                    быстро и
                                                    интересно. После судьбоносного письма о взаимной симпатии мы начали свое электронное общение. Спустя два месяца незабываемых встреч мы решили объединить наши сердца и отправиться в
                                                    романтическое
                                                    путешествие на средиземное море, чтобы полностью насладиться друг другом, не отвлекаясь на повседневную суету мегаполиса. Сегодня наша жизнь наполнена большим смыслом и новыми
                                                    долгосрочными
                                                    планами.
                                                    <br/><br/>
                                                    Спасибо компании ДавайНаСвидание за столь интересный метод свиданий, который соединяет сердца!
                                                    <br/><br/>
                                                    Желаем всем, кто сомневается обязательно идти на свидание в слепую и встретить свою судьбу!!!
                                                    <br/><br/>
                                                    Дмитрий и Татьяна
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide flex items-center justify-center">
                                    <div className="w-full h-full">
                                        <div className="relative flex-full lg:flex justify-end px-8">
                                            <div className="flex justify-center items-start lg:w-6/12 px-8">
                                                <img src="../images/uploads/igor_ksenia.jpg" alt="" className="object-cover relative w-full h-auto top-0 bottom-0"/>
                                            </div>
                                            <div className="lg:w-6/12 flex flex-col justify-start">
                                                <p className="text-blue font-semibold opacity-50">июль 2018 года</p>
                                                <p className="text-xl font-bold">
                                                    <span className="text-blue">Игорь</span>
                                                    <span className="text-blue opacity-50">&</span>
                                                    <span className="text-red">Ксения</span>
                                                </p>
                                                <h5 className="text-lg mt-8">
                                                    Здравствуйте!<br/>
                                                    <br/>
                                                    Меня зовут Игорь. Я познакомился с Ксенией в кафе "Капоне" на метро Бауманская. Она мне понравилась с первого взгляда. У нас много общего. Мне с ней хорошо, эта девушка делает меня
                                                    счастливым. Я
                                                    очень рад что познакомился с ней через ваше мироприятие!<br/>
                                                    <br/>
                                                    Спасибо вам большое!
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide flex items-center justify-center">
                                    <div className="w-full h-full">
                                        <div className="relative flex-full lg:flex justify-end px-8">
                                            <div className="flex justify-center items-start lg:w-6/12 px-8">
                                                <img src="../images/uploads/Yulia_andrey.jpg" alt="" className="object-cover relative w-full h-auto top-0 bottom-0"/>
                                            </div>
                                            <div className="lg:w-6/12 flex flex-col justify-start">
                                                <p className="text-blue font-semibold opacity-50">октябрь 2016 года</p>
                                                <p className="text-xl font-bold">
                                                    <span className="text-blue">Андрей</span>
                                                    <span className="text-blue opacity-50">&</span>
                                                    <span className="text-red">Юлия</span>
                                                </p>
                                                <h5 className="text-lg mt-8">
                                                    Добрый день! Хочется поделиться нашей историей знакомства, которая произошла благодаря Speed Dating! 10/07/15 была вечеринка в клубе "London". Знакомство завязалось и началась наша
                                                    история
                                                    любви
                                                    :) Как заметили по фотографии, 15/06/2016 мы стали семьей!) Ищите своего человека и обязательно найдете! Счастья Вам!
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide flex items-center justify-center">
                                    <div className="w-full h-full">
                                        <div className="relative flex-full lg:flex justify-end px-8">
                                            <div className="flex justify-center items-start lg:w-6/12 px-8">
                                                <img src="../images/uploads/Oleg_Natalia.jpg" alt="" className="object-cover relative w-full h-auto top-0 bottom-0"/>
                                            </div>
                                            <div className="lg:w-6/12 flex flex-col justify-start">
                                                <p className="text-blue font-semibold opacity-50">декабрь 2013 года</p>
                                                <p className="text-xl font-bold">
                                                    <span className="text-blue">Олег</span>
                                                    <span className="text-blue opacity-50">&</span>
                                                    <span className="text-red">Наталья</span>
                                                </p>
                                                <h5 className="text-lg mt-8">
                                                    Здравствуйте!<br/>
                                                    <br/>
                                                    Мы познакомились на встрече 29.12.13 в кафе "Штолле" (На фото мы сидим спинами друг-к другу)<br/>
                                                    <br/>
                                                    17.01.15 мы поженились!) Спасибо, вы наши купидоны!)<br/>
                                                    <br/>
                                                    С любовью, Олег и Наташа.<br/>
                                                    P.S. История из нашей группы ВК
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide flex items-center justify-center">
                                    <div className="w-full h-full">
                                        <div className="relative flex-full lg:flex justify-end px-8">
                                            <div className="flex justify-center items-start lg:w-6/12 px-8">
                                                <img src="../images/uploads/Kirill_olga.jpg" alt="" className="object-cover relative w-full h-auto top-0 bottom-0"/>
                                            </div>
                                            <div className="lg:w-6/12 flex flex-col justify-start">
                                                <p className="text-blue font-semibold opacity-50">январь 2014 года</p>
                                                <p className="text-xl font-bold">
                                                    <span className="text-blue">Кирилл</span>
                                                    <span className="text-blue opacity-50">&</span>
                                                    <span className="text-red">Ольга</span>
                                                </p>
                                                <h5 className="text-lg mt-8">
                                                    На "Быстрые свидания" я попала случайно, - знакомая решила найти себе вторую половину, а меня позвала за компанию. Было интересно, но не больше. Кирилл был первым, с кем я общалась, не
                                                    могу
                                                    сказать, что что-то дрогнуло или я поняла, что это -ОН! Нет, он произвел на меня приятное впечатление, получил плюс и на этом, мы спокойно разошлись. Я не планировала начинать новые
                                                    отношения и
                                                    отмечала симпатичных ребят просто из интереса, совпадем или нет.<br/>
                                                    <br/>
                                                    Через пару дней на почту пришло, порадовавшее меня письмо - все отмеченные ребята ответили мне взаимной симпатией и уже этого было достаточно, я не планировала продолжать общение,
                                                    поэтому
                                                    решила
                                                    вежливо отшить любого, кто мне позвонит или напишет, что и произошло. Кирилл был последним, кто мне написал, и я попыталась дать ему понять, что у нас вряд ли что- то может получиться,
                                                    но
                                                    он
                                                    продолжал писать, и мне стало интересно. Мы стали общаться, потом встретились, а через пару недель у него был день рождения, после которого мы поняли, что хотим быть вместе, нас обоих
                                                    интересовали
                                                    серьезные отношения, нам было интересно .<br/>
                                                    <br/>
                                                    В мае вместе провели незабываемый отпуск в Турции, потом был Питер, Латвия.... Я уверена, что в жизни не бывает случайностей и очень рада, что поддержав знакомую, пошла на "Быстрые
                                                    свидания", где
                                                    встретила мужчину своей мечты.
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide flex items-center justify-center">
                                    <div className="w-full h-full">
                                        <div className="relative flex-full lg:flex justify-end px-8">
                                            <div className="flex justify-center items-start lg:w-6/12 px-8">
                                                <img src="../images/uploads/Evgenii_daria.jpg" alt="" className="object-cover relative w-full h-auto top-0 bottom-0"/>
                                            </div>
                                            <div className="lg:w-6/12 flex flex-col justify-start">
                                                <p className="text-blue font-semibold opacity-50">сентябрь 2016 года</p>
                                                <p className="text-xl font-bold">
                                                    <span className="text-blue">Евгений</span>
                                                    <span className="text-blue opacity-50">&</span>
                                                    <span className="text-red">Дарья</span>
                                                </p>
                                                <h5 className="text-lg mt-8">
                                                    Добрый день, коллектив "давайНасвидание"!<br/>
                                                    <br/>
                                                    Хотим поделиться нашей историей знакомства. Нас зовут Евгений и Дарья, мы познакомились на быстрых свиданиях 17.10.2015,которые проходили в ресторане Белый. На такого рода мероприятии
                                                    мы
                                                    были
                                                    впервые. С первой минуты нашего общения стало понятно, что мы нашли друг друга. В этот же вечер мы начали переписку по смс и через пару дней встретились. Общение друг с другом
                                                    поглотило, с
                                                    тех пор
                                                    мы вместе. 8 марта я сделал Дарье предложение. 18 июня у нас состоялась свадьба. Теперь мы семья Носковых)
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide flex items-center justify-center">
                                    <div className="w-full h-full">
                                        <div className="relative flex-full lg:flex justify-end px-8">
                                            <div className="flex justify-center items-start lg:w-6/12 px-8">
                                                <img src="../images/uploads/Nastia_tomas.jpg" alt="" className="object-cover relative w-full h-auto top-0 bottom-0"/>
                                            </div>
                                            <div className="lg:w-6/12 flex flex-col justify-start">
                                                <p className="text-blue font-semibold opacity-50">декабрь 2013 года</p>
                                                <p className="text-xl font-bold">
                                                    <span className="text-blue">Настя</span>
                                                    <span className="text-blue opacity-50">&</span>
                                                    <span className="text-red">Томас</span>
                                                </p>
                                                <h5 className="text-lg mt-8">
                                                    Добрый день. Хочу рассказать историю знакомства со своей девушкой и про то, какую роль в нем сыграла компания ДавайНаСвидание. Я приехал в Москву из США, из города Чикаго, по программе
                                                    обмена
                                                    студентами между университетом Beloit и РГГУ для углубленного изучения русского языка и литературы на 1 год. Где-то через пол года после моего приезда мой друг Кэмерон, который приехал
                                                    вместе со
                                                    мной, предложил мне поучаствовать в вечере быстрых свиданий в Москве (speed dating) в Dream Bar. Кстати, у нас в Америке я ни разу не участвовал в таких мероприятиях, хотя есть
                                                    стереотип,
                                                    что у
                                                    нас все только так и знакомятся.<br/>
                                                    <br/>
                                                    Так вот, пришел я за компанию с другом, мы, кстати, довольно сильно опоздали. На свиданиях speed dating я хотел повеселиться, попрактиковаться в русском языке и заодно с кем-нибудь
                                                    познакомиться.
                                                    Мне сразу понравилась Настя, мы обменялись контактами еще во время пятиминутного свидания, а после мероприятия пошли за барную стойку и посидели до самой ночи =). Так началась наша
                                                    история
                                                    любви.
                                                    Мы планируем вместе поехать в США и жить там хотя бы до того, как я окончу университет. Настя была в США несколько раз и с удовольствием поддержала предложение. Спасибо компании
                                                    ДавайНаСвидание за
                                                    то, что меняет нашу жизнь к лучшему и организует быстрые знакомства таким интересным образом, удачи Вам, ребята!<br/>
                                                    <br/>
                                                    <br/>
                                                    АНАСТАСИЯ, 25 лет:<br/>
                                                    <br/>
                                                    Первый раз пришла на такие вечера знакомств, честно, даже с недоверием. Оказалось, что именно в этот вечер на мероприятии познакомилась с Томасом. Сначала было просто интересно
                                                    пообщаться
                                                    с
                                                    американцем. Потом стали часто созваниваться, и наши встречи переросли в настоящую любовь) Могу сказать, что мечты все-таки сбываются, я встретила свою половинку, и мы собираемся
                                                    встретить
                                                    Новый
                                                    Год в Нью-Йорке. Хочу всем пожелать не бояться пробовать экспресс знакомства, ведь никогда не знаешь, где и когда найдешь свою любовь! Спасибо организаторам за романтичную и уютную
                                                    атмосферу!<br/>
                                                    P.S. Настя и Томас познакомились в октябре 2013г.<br/>
                                                    В 2014 году Анастасия переехала жить в США<br/>
                                                    В 2015 году Настя вышла замуж! ПОЗДРАВЛЯЕМ!
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                            <div className="swiper-pagination -mb-8"/>
                            <div className="swiper-button-next swiper-button-next-pairs flex justify-center items-center w-10 h-10 rounded-full bg-white shadow-violet-sm">
                                <i className="icon-right-open text-blue-light"/>
                            </div>
                            <div className="swiper-button-prev swiper-button-prev-pairs flex justify-center items-center w-10 h-10 rounded-full bg-white shadow-violet-sm">
                                <i className="icon-left-open text-blue-light"/>
                            </div>
                        </Swiper>

                    </div>
                </div>
            </div>
        </section>
        <section className="guide bg-background1">
            <div className="container mx-auto">
                <div className="py-8 lg:pt-24 lg:pb-28">
                    <h2 className="lg:text-2xl text-xl text-blue font-bold lg:leading-10 leading-5 uppercase">КАК ПОЗНАКОМИТЬСЯ?</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-4 gap-12 lg:gap-16 lg:mt-24 mt-12">
                        <div className="flex lg:block">
                            <img src="../images/sign.svg" alt="" className="w-16 h-16"/>
                            <p className="lg:mt-8 ml-6 lg:ml-0 text-lg">Выберите возрастную<br/> категорию и дату мероприятия<br/> в анонсе</p>
                        </div>
                        <div className="flex lg:block">
                            <img src="../images/calendar.svg" alt="" className="w-16 h-16"/>
                            <p className="lg:mt-8 ml-6 lg:ml-0 text-lg">Оставьте заявку и оплатите<br/> бронь</p>
                        </div>
                        <div className="flex lg:block">
                            <img src="../images/watch.svg" alt="" className="w-16 h-16"/>
                            <p className="lg:mt-8 ml-6 lg:ml-0 text-lg">Приходите на<br/> мероприятие за 10 минут<br/> до начала</p>
                        </div>
                        <div className="flex lg:block">
                            <img src="../images/taster.svg" alt="" className="w-16 h-16"/>
                            <p className="lg:mt-8 ml-6 lg:ml-0 text-lg">Знакомьтесь с 10-15<br/> участниками</p>
                        </div>
                        <div className="flex lg:block">
                            <img src="../images/table.svg" alt="" className="w-16 h-16"/>
                            <p className="lg:mt-8 ml-6 lg:ml-0 text-lg">По желанию развлекательная<br/> программа</p>
                        </div>
                        <div className="flex lg:block">
                            <img src="../images/gift.svg" alt="" className="w-16 h-16"/>
                            <p className="lg:mt-8 ml-6 lg:ml-0 text-lg">Узнайте Ваши симпатиии<br/> через 30 минут</p>
                        </div>
                        <div className="flex lg:block">
                            <img src="../images/birds.svg" alt="" className="w-16 h-16"/>
                            <p className="lg:mt-8 ml-6 lg:ml-0 text-lg">Созванивайтесь<br/> и встречайтась</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="description">
            <div className="container mx-auto">
                <div className=" pt-5 lg:pt-20 md:pb-80 pb-60 bg-no-repeat bg-bottom bg-contain" style={{ 'backgroundImage': 'url(../images/description.svg)' }}>
                    <h2 className="lg:text-2xl text-xl text-blue font-bold lg:leading-10 leading-5 uppercase">КАК ПРОХОДЯТ<br/> БЫСТРЫЕ СВИДАНИЯ</h2>

                    <p className="mt-12 text-lg lg:text-xl"><b className="text-blue opacity-50">Speed dating</b> от нашей компании проходит в изысканных заведениях Москвы. Мы знаем, что важны<br/>
                        детали во всём: заведение, музыка, отношение к людям. Мы очень строго относимся к деталям, и<br/>
                        это выделяет нас из огромного числа компаний. У нас Вы получите отличный сервис, прекрасный<br/>
                        вечер и свою вторую половинку. Мы делаем доступный и качественный сервис.</p>
                    <div className="mt-16">
                        <div className="flex justify-center">
                            <div className=" xl:w-7/12 md:w-11/12 w-full">
                                <Swiper className="w-full h-full relative overflow-hidden"
                                        spaceBetween={30}
                                        loop={true}
                                        slidesPerView={1}
                                        navigation={{
                                            prevEl: ".swiper-button-prev",
                                            nextEl: ".swiper-button-next",
                                        }}
                                        pagination={{ clickable: true }}>
                                    <SwiperSlide className="swiper-slide flex items-center justify-center">
                                        <div className="flex-full flex justify-center my-6">
                                            <div className="flex lg:p-4 p-4 pt-8 bg-white rounded-md lg:w-8/12 w-full lg:shadow-violet-sm">
                                                <span className="text-yellow text-2xl leading-10">1</span>
                                                <ul className="ml-2 list-disc pl-4">
                                                    <li className="text-lg">10-15 мини — свиданий в ресторане</li>
                                                    <li className="text-lg">Мы тщательно выбираем рестораны.</li>
                                                    <li className="text-lg">У нас есть 7 жестких критериев отбора заведения</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide flex items-center justify-center">
                                        <div className="flex-full flex justify-center my-6">
                                            <div className="flex lg:p-4 p-4 pt-8 bg-white rounded-md lg:w-8/12 w-full lg:shadow-violet-sm">
                                                <span className="text-yellow text-2xl leading-10">2</span>
                                                <ul className="ml-2 list-disc pl-4">
                                                    <li className="text-lg">Каждое свидание длится по 7 минут</li>
                                                    <li className="text-lg">За это время Вы поймете симпатичен Вам человек или нет</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide flex items-center justify-center">
                                        <div className="flex-full flex justify-center my-6">
                                            <div className="flex lg:p-4 p-4 pt-8 bg-white rounded-md lg:w-8/12 w-full lg:shadow-violet-sm">
                                                <span className="text-yellow text-2xl leading-10">3</span>
                                                <ul className="ml-2 list-disc pl-4">
                                                    <li className="text-lg">Вы сами задаете тему разговора</li>
                                                    <li className="text-lg">Общение идет в свободной форме</li>
                                                    <li className="text-lg">Вы можете быть серьезным, а можете шутить</li>
                                                    <li className="text-lg">Протестируйте собеседника со всех сторон ;)</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide flex items-center justify-center">
                                        <div className="flex-full flex justify-center my-6">
                                            <div className="flex lg:p-4 p-4 pt-8 bg-white rounded-md lg:w-8/12 w-full lg:shadow-violet-sm">
                                                <span className="text-yellow text-2xl leading-10">4</span>
                                                <ul className="ml-2 list-disc pl-4">
                                                    <li className="text-lg">Пересаживаются мужчины</li>
                                                    <li className="text-lg">За вечер Вы познакомитесь с каждым участником противоположного пола.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide flex items-center justify-center">
                                        <div className="flex-full flex justify-center my-6">
                                            <div className="flex lg:p-4 p-4 pt-8 bg-white rounded-md lg:w-8/12 w-full lg:shadow-violet-sm">
                                                <span className="text-yellow text-2xl leading-10">5</span>
                                                <ul className="ml-2 list-disc pl-4">
                                                    <li className="text-lg">Вы узнаете всех, кому Вы понравились</li>
                                                    <li className="text-lg">Вы увидете номера телефонов всех, кто поставил Вам "+" в своей анкете.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <div className="swiper-pagination -mb-8"/>
                                    <div className="swiper-button-next flex justify-center items-center w-10 h-10 rounded-full bg-white shadow-violet-sm">
                                        <i className="icon-right-open text-blue-light"/>
                                    </div>
                                    <div className="swiper-button-prev flex justify-center items-center w-10 h-10 rounded-full bg-white shadow-violet-sm">
                                        <i className="icon-left-open text-blue-light"/>
                                    </div>
                                </Swiper>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="reviews bg-background1" id="reviews">
            <div className="container mx-auto">
                <div className="py-16 lg:pt-36 lg:pb-36">
                    <h2 className="lg:text-2xl text-xl text-blue font-bold lg:leading-10 leading-5 uppercase">
                        ОТЗЫВЫ УЧАСТНИКОВ
                    </h2>
                    <div className="mt-16 lg:mt-36">
                        <Swiper className="w-full h-full relative overflow-hidden py-8"
                                loop={true}
                                slidesPerView={1}
                                autoHeight={true}
                                navigation={{
                                    prevEl: ".swiper-button-prev",
                                    nextEl: ".swiper-button-next",
                                }}
                                breakpoints={{
                                    1100: {
                                        slidesPerView: 2,
                                    },
                                }}
                                pagination={{ clickable: true }}
                        >
                            <div className="swiper-button-next swiper-reviews-next flex justify-center items-center w-10 h-10 rounded-full bg-white shadow-violet-sm">
                                <i className="icon-right-open text-blue-light"/>
                            </div>
                            <div className="swiper-button-prev swiper-reviews-prev flex justify-center items-center w-10 h-10 rounded-full bg-white shadow-violet-sm">
                                <i className="icon-left-open text-blue-light"/>
                            </div>

                            <SwiperSlide className="swiper-slide px-8 flex items-center justify-center ">
                                <div className="relative bg-white p-8 shadow-violet-sm">
                                    <div className="flex">
                                        <img src="../images/uploads/Otzyv_Michail.png" className="w-16 h-16 rounded-full" alt=""/>
                                        <div className="ml-5">
                                            <p className="text-blue font-semibold opacity-50">03.09.2015</p>
                                            <p className="text-xl font-bold">
                                                <span className="text-blue">Михаил, </span>
                                                <span className="text-blue opacity-50">27 лет</span>
                                            </p>
                                        </div>
                                    </div>
                                    <p className="text-lg mt-8">Думал, если честно, что девушки на такие мероприятия не ходят. После
                                        вечеринки мы остались в кафе с одной из участниц и сидели до самого закрытия. Было здорово!)</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide px-8 flex items-center justify-center ">
                                <div className="relative bg-white p-8 shadow-violet-sm">
                                    <div className="flex">
                                        <img src="../images/uploads/Otzyv_Nastya.png" className="w-16 h-16 rounded-full" alt=""/>
                                        <div className="ml-5">
                                            <p className="text-blue font-semibold opacity-50">03.09.2013</p>
                                            <p className="text-xl font-bold">
                                                <span className="text-red">Настя, </span>
                                                <span className="text-blue opacity-50">25 лет</span>
                                            </p>
                                        </div>
                                    </div>
                                    <p className="text-lg mt-8">Спасибо большое команде организаторов «ДавайНаСвидание», помимо новых интересных знакомств, я получила массу удовольствия от общения, а его в нашей сумасшедшей жизни
                                        часто
                                        не
                                        хватает...
                                        <br/><br/>
                                        А, главное, я здесь нашла себе парня!</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide px-8 flex items-center justify-center ">
                                <div className="relative bg-white p-8 shadow-violet-sm">
                                    <div className="flex">
                                        <img src="../images/uploads/Otzyv_Vika.png" className="w-16 h-16 rounded-full" alt=""/>
                                        <div className="ml-5">
                                            <p className="text-blue font-semibold opacity-50">15.07.2016</p>
                                            <p className="text-xl font-bold">
                                                <span className="text-red">Вика, </span>
                                                <span className="text-blue opacity-50">27 лет</span>
                                            </p>
                                        </div>
                                    </div>
                                    <p className="text-lg mt-8">Первый раз была на таком мероприятии, как speed dating. Очень удивила приятная и открытая атмосфера. Познакомилась с интересным человеком;) Всем девушкам рекомендую
                                        посетить подобное
                                        мероприятие. Спасибо за прекрасный вечер!</p>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
        <section className="faq">
            <div className="container mx-auto">
                <div className="py-8 lg:py-32">
                    <h2 className="lg:text-2xl text-xl text-blue font-bold lg:leading-10 leading-5 uppercase">
                        ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ
                    </h2>
                    <div className="mt-16">
                        <div className="accordeon border-blue border-t-2">
                            {faq.map((item, id) => (
                                <AccordeonItem item={item} key={id}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="about bg-background1" id="about">
            <div className="container mx-auto">
                <div className="py-8 lg:py-32">
                    <h2 className="lg:text-2xl text-xl text-blue font-bold lg:leading-10 leading-5 uppercase">
                        О НАС
                    </h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-6 lg:gap-y-24 mt-4">
                        <div>
                            <p className="text-lg"><b className="text-blue">«Давайнасвидание»</b> - профессиональный организатор
                                Быстрых свиданий <b className="text-blue opacity-50">(speed dating)</b>, популярного в Европе и США
                                способа знакомств. Почти 10 лет москвичи и не только
                                собираются в ресторанах в центре Москвы, чтобы
                                познакомиться. И уже через полчаса, как вы кому-то
                                понравились, вы узнаете, испытывает ли этот человек
                                симпатию к вам. Вас, конечно же, интересует статистика?!
                                Свадьбы?</p>
                            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="flex lg:block items-center">
                                    <div className="flex flex-col lg:flex-row">
                                        <img src="images/hearts.svg" alt=""/>
                                        <div className="lg:ml-4">
                                            <p className="text-blue opacity-50 font-semibold text-center lg:text-left">знакомств</p>
                                            <span className="font-extrabold block text-red text-xl text-center lg:text-left">100</span>
                                        </div>
                                    </div>
                                    <p className="text-lg lg:mt-4 mt-0 lg:ml-0 ml-12">
                                        Каждую неделю мы<br/>
                                        проводим более 100<br/>
                                        знакомств с разными людьми
                                    </p>
                                </div>
                                <div className="flex lg:block items-center">
                                    <div className="flex flex-col lg:flex-row">
                                        <img src="images/cupid.svg" alt=""/>
                                        <div className="lg:ml-4">
                                            <p className="text-blue opacity-50 font-semibold text-center lg:text-left">семей</p>
                                            <span className="font-extrabold block text-red text-xl text-center lg:text-left">1000</span>
                                        </div>
                                    </div>
                                    <p className="text-lg lg:mt-4 mt-0 lg:ml-0 ml-12">
                                        За время нашей работы<br/>
                                        создано почти 1000 семей</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className="text-lg"><b className="text-blue">Наша цель</b> - сделать доступным знакомства в Москве с
                                интересными людьми, в том числе для серьёзных отношений и
                                для создания семьи, т.к. в нашем современном мире все
                                сложнее и сложнее людям познакомиться и найти друг друга.
                                Почему? Где искать и где взять время на поиск, когда едва
                                хватает времени доехать с работы домой? На улице в наше
                                время все боятся заговорить с посторонним человеком, в
                                метро все внимание в гаджетах , на работе - табу. На сайтах
                                знакомств - вообще отдельная история. Согласны?
                                И еще не трудно догадаться, что вас интересует репутация
                                агентства?</p>
                        </div>
                        <div className="flex justify-center">
                            <img src="images/uploads/founder.jpg" alt="" className="object-cover w-2/4"/>
                        </div>
                        <div>
                            <div>
                                <div className="flex justify-between">
                                    <div>
                                        <p className="text-blue opacity-50 font-semibold">Директор и основатель агентства</p>
                                        <p className="text-red text-xl font-bold">Юлия Антоненко</p>
                                    </div>
                                    <img className="hidden lg:block" src="images/uploads/signature.png" alt=""/>
                                </div>
                            </div>
                            <p className="text-lg mt-12">Директор и основатель агентства, Юлия Антоненко - постоянный
                                гость федеральных телевизионных каналов -
                                <a className="text-red" href="/#media">мы в СМИ</a>
                                <br/><br/>
                                Но, самое главное, счастливая жена и мама, которая любит делать людей счастливыми.
                                <br/><br/>
                                Мы гарантируем безопасность и конфединциальность вашей
                                персональной информации и, при желании, с удовольствием
                                предложим вам познакомиться с VIP-клиентами нашего клуба знакомств <a className="text-red" href="https://marriedandhappy.ru/">Брачное агентство</a></p>
                            <a href={"/#announce"} className="block text-center w-full md:w-auto mt-8 lg:hidden py-3 px-8 text-white bg-red rounded font-lg font-bold
                                    hover:bg-red-light active:bg-red-saturated hover:shadow-red-light active:bg-red-saturated">ЗАПИСАТЬСЯ
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="contacts">
            <div className="container mx-auto">
                <div className="py-8 lg:py-32">
                    <div className="lg:flex">
                        <div className="lg:w-5/12">
                            <div className="lg:mt-8">
                                <h2 className="lg:text-2xl text-xl text-blue font-bold lg:leading-10 leading-5 uppercase">
                                    КОНТАКТЫ
                                </h2>
                                <p className="text-red text-xl font-bold mt-8 lg:mt-4">НАШ ОФИС</p>
                                <div className="flex mt-4">
                                    <i className="icon-location text-blue text-lg opacity-50"/>
                                    <address className="ml-2 not-italic text-lg">
                                        г. Москва, Милютинский переулок, <br/>
                                        дом 8, строение 1, офис 23
                                    </address>
                                </div>

                                {/*<div className="flex lg:mt-16 mt-4">*/}
                                {/*    <i className="icon-position text-lg text-blue opacity-50"/>*/}
                                {/*    <p className="text-lg text-blue opacity-50 font-bold ml-2">*/}
                                {/*        Как добраться от метро Менделеевская*/}
                                {/*    </p>*/}
                                {/*</div>*/}
                                {/*<p className="text-lg mt-4">Из стеклянных дверей в метро<br/>*/}
                                {/*    поворачиваете направо. При выходе из<br/>*/}
                                {/*    метро идёте направо вдоль улицы<br/>*/}
                                {/*    Новослободская 100 метров до дома 26<br/>*/}
                                {/*    строение 1. Заходите в подъезд 1, этаж 5,<br/>*/}
                                {/*    офис 555*/}
                                {/*</p>*/}

                                <p className="text-red text-xl font-bold mt-4">НАШИ КОНТАКТЫ</p>
                                <a className="flex items-center text-lg mt-5" href="tel:+89266362046">
                                    <i className="icon-viber text-blue opacity-50"/>
                                    <span className="ml-2">8 (926) 636-20-46</span>
                                </a>
                                <a className="flex items-center mt-2" href="mailto:davaynasvidanie@yandex.ru">
                                    <i className="icon-mail text-blue opacity-50"/>
                                    <span className="ml-3 text-lg">davaynasvidanie@yandex.ru</span>
                                </a>
                            </div>
                        </div>
                        <div className="lg:w-7/12 mt-4 lg:mt-0">
                            <div className="lg:pl-6">
                                <div style={{ "position": "relative", "overflow": "hidden", "width": "100%" }}>
                                    <a href="https://yandex.ru/maps/213/moscow/?utm_medium=mapframe&utm_source=maps" style={{ "color": "#eee", "fontSize": "12px", "position": "absolute", "top": "0px" }}>Москва</a>
                                    <a href="https://yandex.ru/maps/213/moscow/house/novoslobodskaya_ulitsa_26s1/Z04Ycw5nQUwOQFtvfXt5cn5lYg==/?ll=37.597899%2C55.783266&utm_medium=mapframe&utm_source=maps&z=16.62"
                                       style={{ "color": "#eee", "fontSize": "12px", "position": "absolute", "top": "14px" }}>Новослободская улица, 26с1 — Яндекс.Карты</a>
                                    <iframe src="https://yandex.ru/map-widget/v1/-/CCU4VHdOCA" width="100%" height="590" frameBorder="1" allowFullScreen={true} style={{ 'position': 'relative' }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}
