import React from 'react';
import { useDispatch } from "react-redux";
import { openMenu } from "../store";
import MobileMenu from "./MobileMenu";

export default function Header() {
    const dispatch = useDispatch();

    return (<>
        <header className="header bg-background2">
            <div className="container mx-auto">
                <div className="flex items-center justify-between pt-3 pb-8 lg:py-4">
                    <a href="/">
                        <img src="../images/logo.svg" className="h-16" alt="logo"/>
                    </a>
                    <nav className="header__nav lg:flex hidden items-center">
                        <a className="text-blue hover:text-blue-light active:text-blue-saturated text-lg"
                           href="/#announce">Анонс</a>
                        <a className="text-blue hover:text-blue-light active:text-blue-saturated text-lg"
                           href="/#about">О нас</a>
                        <a className="text-blue hover:text-blue-light active:text-blue-saturated text-lg"
                           href="/#reviews">Отзывы</a>
                        <a className="text-blue hover:text-blue-light active:text-blue-saturated text-lg"
                           href="https://marriedandhappy.ru" target="_blank">Брачное агентство</a>
                    </nav>
                    <div className="flex items-center">
                        <div className="lg:flex hidden items-center">
                            <a href="tel:+79266362046" className="mr-5 text-blue hover:text-blue-light active:text-blue-saturated  text-lg">+7 (926) 636 20 46</a>
                            <a href="/cabinet/list.php" className="border-2 border-blue rounded px-8
                            py-3 font-semibold text-blue hover:border-blue-light hover:text-blue-light
                            active:border-blue-saturated active:text-blue-saturated">Войти</a>

                        </div>
                        <button className="lg:hidden" onClick={() => dispatch(openMenu())}>
                            <i className="icon-menu text-blue text-3xl"></i>
                        </button>
                    </div>
                </div>
            </div>
        </header>
    </>)
}