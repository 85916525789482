import React from 'react';

export default function Footer(props) {
    return (<>
            <footer>
                <div className="container mx-auto">
                    <div className="lg:py-16 py-7 border-t border-dashed border-blue-light">
                        <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
                            <a href="/" className="flex justify-center row-start-1 row-end-2">
                                <img src="../images/logo.svg" className="h-24" alt="logo"/>
                            </a>
                            <div className="flex row-start-3 row-end-4 lg:row-start-1 lg:row-end-2 mt-16 lg:mt-0">
                                <ul>
                                    <li>
                                        <a href="/#announce" className="text-lg text-blue">Анонс</a>
                                    </li>
                                    <li className="mt-4">
                                        <a href="/#about" className="text-lg text-blue">О нас</a>
                                    </li>
                                    <li className="mt-4">
                                        <a href="/#reviews" className="text-lg text-blue">Отзывы</a>
                                    </li>
                                    <li className="mt-4">
                                        <a href="https://marriedandhappy.ru" target="_blank" className="text-lg text-blue">Брачное агентство</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="flex flex-col row-start-2 row-end-3 lg:row-start-1 lg:row-end-2">
                                <div className="mt-4 lg:mt-0 flex order-2 lg:order-1 justify-center lg:justify-start">
                                    <a href="https://vk.com/marriedandhappymoscow" className="mr-2 flex">
                                        <i className="icon-vk text-blue text-2xl flex h-mincontent"></i>
                                    </a>
                                </div>
                                <div className="lg:mt-5 order-1 lg:order-2">
                                    <a href="tel:+79266362046" className="block text-center lg:text-left text-blue text-lg">+7 (926) 636 20 46</a>
                                    <a href="mailto:davaynasvidanie@yandex.ru" className="block text-center lg:text-left text-blue text-lg mt-4">davaynasvidanie@yandex.ru</a>
                                </div>
                            </div>
                            <div className="flex justify-center lg:justify-end row-start-5 row-end-5 lg:row-start-1 lg:row-end-2">
                                <button className="text-blue flex flex-col items-center" onClick={() => window.scrollTo(0, 0)}>
                                    <i className="icon-up-circled2 text-2xl flex"></i>
                                    <span className="text-lg mt-5">Вернуться наверх</span>
                                </button>
                            </div>
                        </div>
                        <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-x-6 gap-y-3 mt-28">
                            {props && props.seoLinks ? (<>
                                {props.seoLinks.map((seo, id) => (
                                    <a key={id}  className="text-blue" href={`${seo.url}`}>{seo.name}</a>
                                ))}
                                <a className="text-blue" href="/terms_of_service">Пользовательское соглашение</a>
                                <a className="text-blue" href="/privacy_policy">Политика конфиденциальности</a>
                            </>) : null}
                        </div>
                        <div className="flex grid lg:grid-cols-3 grid-cols-1 mt-28 text-center">
                            <p className="text-blue text-tn lg:text-base col-start-1 col-end-2 lg:col-start-2 lg:col-end-3">© ДавайНАсвидание, 2023</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
