import React, { useState } from "react";

export default function AccordeonItem(props) {
    const item = props.item;

    const [open, setOpen] = useState(false);

    return (<div className="accordeon__item group outline-none border-blue border-b-2" >
        <div className="accordeon__item_header group transition ease duration-500 cursor-pointer flex items-center py-4 lg:py-8 justify-between" onClick={() => setOpen(!open)}>
            <span className="text-lg lg:text-xl text-blue font-semibold">{item.title}</span>
            <div className="accordeon__item_header-icon transform transition ease duration-500 ml-4" style={open ? {transform: "rotateZ(45deg)"} : {}}>
                <i className="icon-plus text-blue text-xl"/>
            </div>
        </div>
        <div className={`accordeon__item_content group-focus:max-h-screen max-h-0 overflow-hidden ease duration-500`} style={open ? { maxHeight: "100vh" } : {}}>
            <p className="text-lg text-blue lg:w-10/12 pb-4 lg:pb-8" dangerouslySetInnerHTML={{__html: item.text}}/>
        </div>
    </div>);
}