import React from "react";
import { useDispatch } from "react-redux";
import { openPopupEvent } from "../store";
import {PHOTOS_BASE_URL} from "../app.config";

export default function EventCard(props) {
    const event = props.event;
    const dispatch = useDispatch();

    return (<>
        <div className={`events__item js-event-age-group-${event.age_group_text}`} data-age-group={event.age_group}>
            <a href={`/cafe/${event.cafe_alias}.html?eid=${event.id}`}
               className="h-52 lg:h-80 overflow-hidden flex items-center relative rounded-tl rounded-tr bg-center bg-no-repeat bg-cover"
               style={{ backgroundImage: `url(${PHOTOS_BASE_URL}${event.cafe_photo})` }}>
                <div className="absolute h-full w-full bg-gradient-to-t from-violet via-blue to-transparent opacity-50">

                </div>
                <div className="absolute p-4 lg:p-6 top-0 left-0 h-full w-full flex flex-col justify-between">
                    <div>
                        <div>
                            <span className="flex items-center">
                                <i className="icon-location text-white"/>
                                <span className="text-white text-lg font-bold ml-3">
                                    {event.cafe_address}
                                </span>
                            </span>
                            <span className="flex items-center">
                                <i className="icon-metro text-white"/>
                                <span className="text-white text-lg font-bold ml-3">
                                    {event.cafe_metro}
                                </span>
                           </span>
                        </div>
                        <div className="grid grid-cols-2 gap-4 lg:block">
                               <span className="flex items-center">
                                    <span className="text-white text-lg font-bold">М</span>
                                    <span className="text-white text-lg font-bold ml-3">
                                        {event.min_age_m}-{event.max_age_m}
                                    </span>
                               </span>
                            <span className="flex items-center">
                                <span className="text-white text-lg font-bold">Ж</span>
                                <span className="text-white text-lg font-bold ml-3">
                                    {event.min_age_f}-{event.max_age_f}
                                </span>
                            </span>
                        </div>
                    </div>

                    <div>
                        <p className="text-white text-lg opacity-80 font-bold">МЕСТО ПРОВЕДЕНИЯ</p>
                        <p className="text-white text-xl font-extrabold">{event.cafe_name}</p>
                    </div>
                </div>
            </a>
            <div className="bg-white p-4 lg:px-7 rounded relative -top-2 grid grid-cols-2 lg:grid-cols-3 shadow-violet-sm">
                <p className="font-semibold text-blue-light col-start-1 col-end-2 row-start-1 row-end-2">{event.__weekday}</p>
                <p className="font-bold text-blue text-lg col-start-1 col-end-2 row-start-2 row-end-3">{event.__date_str}</p>
                <p className="font-semibold text-blue-light col-start-2 col-end-3 row-start-1 row-end-2 text-right lg:text-left">стоимость</p>
                <p className="font-bold text-yellow text-lg col-start-2 col-end-3 row-start-2 row-end-3 text-right lg:text-left">{event.price} ₽</p>
                <button className="py-3 mt-4 lg:mt-0 text-white bg-red rounded text-xl font-extrabold
                                        hover:bg-red-light active:bg-red-saturated hover:shadow-red-light active:bg-red-saturated
                                        row-start-5 row-end-6 col-start-1 col-end-3
                                        lg:col-start-3 lg:col-end-4 lg:row-start-1 lg:row-end-3"
                        onClick={() => dispatch(openPopupEvent(event))}>Я иду
                </button>
                <p className="font-semibold lg:font-lg text-blue-light col-start-1 col-end-3 row-start-3 row-end-4 lg:col-end-4 mt-4">Описание</p>
                <p className="text-black col-start-1 col-end-3 lg:col-end-4 row-start-4 row-end-5 overflow-ellipsis overflow-hidden ... h-10">
                    {event.description}
                </p>
            </div>
        </div>
    </>);
}