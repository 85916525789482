import React  from 'react';

export default function Cabinet() {

    return (<>
        <div className={"bg-violet"}>

            <div className="container mx-auto pt-8 pb-32">
                <h1 className={"lg:text-2xl text-xl text-yellow font-bold lg:leading-10 leading-5 block uppercase"}>Личный кабинет</h1>
                <span className={"lg:text-2xl text-xl text-white font-bold lg:leading-10 leading-5 block uppercase"}>Не работает :(</span>

                <div className={"lg:text-xl text-lg text-white py-8 uppercase"}>
                    <p>Но есть и хорошая новость - <span className={"text-yellow"}>это временно</span></p>
                    <p>Вас ожидает обновленный дизайн и улучшенный функционал!</p>
                </div>

                <div className={"lg:text-xl text-lg text-white py-8"}>
                    <p>А, пока что, мы будем рады видеть Вас на нашей вечеринке</p>
                </div>

                <a href={"/#announce"} className="block text-center w-full md:w-auto mt-8 py-3 px-8 text-white bg-red rounded font-lg font-bold
                                    hover:bg-red-light active:bg-red-saturated hover:shadow-red-light active:bg-red-saturated">ЗАПИСАТЬСЯ НА ВЕЧЕРИНКУ
                </a>
            </div>

        </div>

    </>);
}