import React from 'react';
import { Helmet } from "react-helmet";

export default function TermsOfService() {
    return (<>
        <Helmet>
            <title>Пользовательское соглашение - ДавайНаСвидание</title>
        </Helmet>
        <div className="bg-background1 pt-14 pb-20">
            <section>
                <div className="container mx-auto">
                    <div>
                        <h2 className="text-xl lg:text-2xl font-bold text-blue uppercase">ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h2>
                        <p>
                            <br/>1. ОБЩИЕ ПОЛОЖЕНИЯ:
                            <br/>1.1. Настоящее Пользовательское соглашение (далее - Соглашение) относится к сайту «davaynasvidanie.ru», расположенному по адресу www.davaynasvidanie.ru
                            <br/>1.2. Сайт «davaynasvidanie.ru» (далее - Сайт) является собственностью юридического лица ООО «ДавайНаСвидание» (ОГРН: 1147746757850, ИНН: 7725834158, адрес регистрации: г. Москва, Милютинский переулок, д.8
                            стр.1, офис
                            22,23,24)
                            <br/>1.3. Настоящее Соглашение регулирует отношения между Администрацией сайта «davaynasvidanie.ru» (далее - Администрация сайта) и Пользователем данного Сайта.
                            <br/>1.4. Администрация сайта оставляет за собой право в любое время изменять, добавлять или удалять пункты настоящего Соглашения без уведомления Пользователя.
                            <br/>1.5. Использование Сайта Пользователем означает принятие Соглашения и изменений, внесенных в настоящее Соглашение.
                            <br/>1.6. Пользователь несет персональную ответственность за проверку настоящего Соглашения на наличие изменений в нем.
                        </p>
                        <p>
                            <br/>2. ОПРЕДЕЛЕНИЯ ТЕРМИНОВ
                            <br/>2.1. Перечисленные ниже термины имеют для целей настоящего Соглашения следующее значение:
                            <br/>2.1.1 «davaynasvidanie.ru» - Интернет-ресурс, расположенный на доменном имени www.davaynasvidanie.ru, осуществляющий свою деятельность посредством Интернет-ресурса и сопутствующих ему сервисов (далее -
                            Сайт).
                            <br/>2.1.2. «davaynasvidanie.ru» - сайт, содержащий информацию об Услугах, Исполнителе услуг, позволяющий осуществить выбор, заказ и получение услуги.
                            <br/>2.1.3. Администрация сайта - уполномоченные сотрудники на управления Сайтом, действующие от имени юридического лица ООО «ДавайНаСвидание».
                            <br/>2.1.4. Пользователь сайта (далее - Пользователь) - лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.
                            <br/>2.1.5. Содержание сайта (далее - Содержание) - охраняемые результаты интеллектуальной деятельности, включая тексты литературных произведений, их названия, предисловия, аннотации, статьи, иллюстрации,
                            обложки,
                            <br/>музыкальные произведения с текстом или без текста, графические, текстовые, фотографические, производные, составные и иные произведения, пользовательские интерфейсы, визуальные интерфейсы, названия товарных
                            <br/>знаков, логотипы, программы для ЭВМ, базы данных, а также дизайн, структура, выбор, координация, внешний вид, общий стиль и расположение данного Содержания, входящего в состав Сайта и другие объекты
                            <br/>интеллектуальной собственности все вместе и/или по отдельности, содержащиеся на сайте www.davaynasvidanie.ru
                        </p>
                        <p>
                            <br/>3. ПРЕДМЕТ СОГЛАШЕНИЯ
                            <br/>3.1. Предметом настоящего Соглашения является предоставление Пользователю доступа к оказываемым услугам.
                            <br/>3.1.1. Сайт предоставляет Пользователю следующие виды услуг:
                            <br/>• ознакомление с услугами, размещенными на Сайте;
                            <br/>• выбор и заказ услуг для осуществления последующей покупки
                            <br/>3.1.2. Под действие настоящего Соглашения подпадают все существующие (реально функционирующие) на данный момент услуги (сервисы) Сайта, а также любые их последующие модификации и появляющиеся в дальнейшем
                            дополнительные услуги (сервисы).
                            <br/>3.2. Доступ к сайту предоставляется на бесплатной основе.
                            <br/>3.3. Настоящее Соглашение является публичной офертой. Получая доступ к Сайту Пользователь считается присоединившимся к настоящему Соглашению.
                            <br/>3.4. Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации
                        </p>
                        <p>
                            <br/>4. ПРАВА И ОБЯЗАННОСТИ СТОРОН
                            <br/>4.1. Администрация сайта вправе:
                            <br/>4.1.1. Изменять правила пользования Сайтом, а также изменять содержание данного Сайта. Изменения вступают в силу с момента публикации новой редакции Соглашения на Сайте.
                            <br/>4.2. Пользователь вправе:
                            <br/>4.2.1. Пользоваться всеми имеющимися на Сайте услугами, а также приобретать любые Услуги, предлагаемые на Сайте.
                            <br/>4.2.2. Задавать любые вопросы, относящиеся к услугам сайта:
                            <br/>• по телефону: +79266362046
                            <br/>• по электронной почте: davaynasvidanie@yandex.ru
                            <br/>• через Форму обратной связи, расположенную по адресу: www.davaynasvidanie.ru
                            <br/>4.2.3. Пользоваться Сайтом исключительно в целях и порядке, предусмотренных Соглашением и не запрещенных законодательством Российской Федерации.
                            <br/>4.2.5. Требовать от администрации скрытия любой информации о пользователе.
                            <br/>4.2.6. Использовать информацию сайта в коммерческих целях без специального разрешения.
                            <br/>4.3. Пользователь Сайта обязуется:
                            <br/>4.3.1. Предоставлять по запросу Администрации сайта дополнительную информацию, которая имеет непосредственное отношение к предоставляемым услугам данного Сайта.
                            <br/>4.3.2. Соблюдать имущественные и неимущественные
                            права авторов и иных правообладателей при использовании Сайта.
                            <br/>4.3.3. Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную работу Сайта.
                            <br/>4.3.4. Не распространять с использованием Сайта любую конфиденциальную и охраняемую законодательством Российской Федерации информацию о физических либо юридических лицах.
                            <br/>4.3.5. Избегать любых действий, в результате которых может быть нарушена конфиденциальность охраняемой законодательством Российской Федерации информации.
                            <br/>4.3.6. Не использовать Сайт для распространения информации
                            рекламного характера, иначе как с согласия Администрации сайта.
                            <br/>4.3.7. Не использовать сервисы с целью:
                            <br/>4.3.7.1. нарушения прав несовершеннолетних лиц и (или) причинение им вреда в любой форме.
                            <br/>4.3.7.2. ущемления прав меньшинств.
                            <br/>4.3.7.3. представления себя за другого человека или представителя организации и (или) сообщества без достаточных на то прав, в том числе за сотрудников данного сайта.
                            <br/>4.3.7.4. введения в заблуждение относительно свойств и характеристик какого-либо
                            Товара и/или услуги, размещенных на Сайте.
                            <br/>4.3.7.5. некорректного сравнения Товара и/или Услуги, а также формирования негативного отношения к лицам, (не) пользующимся определенными услугами, или осуждения таких лиц.
                            <br/>4.3.7.6. загрузки контента, который является незаконным, нарушает любые права третьих лиц; пропагандирует насилие, жестокость, ненависть и (или) дискриминацию по расовому, национальному, половому,
                            религиозному, социальному признакам; содержит недостоверные сведения и (или) оскорбления в адрес конкретных лиц, организаций, органов власти.
                            <br/>4.3.7.7. побуждения к совершению противоправных действий, а также содействия лицам, действия которых направлены на нарушение ограничений и запретов, действующих на территории Российской Федерации.
                            <br/>4.3.8. Обеспечить достоверность предоставляемой информации
                            <br/>4.3.9. Обеспечивать
                            сохранность личных данных от доступа третьих лиц.
                            <br/>4.4. Пользователю запрещается:
                            <br/>4.4.1. Использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические устройства или эквивалентные ручные процессы для доступа, приобретения, копирования или отслеживания содержания
                            Сайта.
                            <br/>4.4.2. Нарушать надлежащее функционирование Сайта.
                            <br/>4.4.3. Любым способом обходить навигационную структуру Сайта для получения или попытки получения любой информации, документов или материалов любыми средствами, которые специально не представлены сервисами
                            данного Сайта.
                            <br/>4.4.4. Несанкционированный доступ к функциям Сайта, любым другим системам или сетям, относящимся к данному Сайту, а также к любым услугам, предлагаемым на Сайте.
                            <br/>4.4.4. Нарушать систему безопасности или аутентификации на Сайте или в любой сети, относящейся к Сайту.
                            <br/>4.4.5. Выполнять обратный поиск, отслеживать или пытаться отслеживать любую информацию о любом другом Пользователе Сайта.
                            <br/>4.4.6. Использовать Сайт и его Содержание в любых целях, запрещенных законодательством Российской Федерации, а также подстрекать к любой незаконной деятельности или другой деятельности, нарушающей права
                            Сайта или других лиц.
                        </p>
                        <p>
                            <br/>5. ИСПОЛЬЗОВАНИЕ САЙТА
                            <br/>5.1. Сайт и Содержание, входящее в состав Сайта, принадлежит и управляется Администрацией сайта.
                            <br/>5.2. Содержание Сайта защищено авторским правом, законодательством о товарных знаках, а также другими правами, связанными с интеллектуальной собственностью, и законодательством о недобросовестной
                            конкуренции.
                            <br/>5.3. Настоящее Соглашение распространяет свое действия на все дополнительные положения и условия оказания услуг, предоставляемых на Сайте.
                            <br/>5.4. Информация, размещаемая на Сайте не должна истолковываться как изменение настоящего Соглашения.
                            <br/>5.5. Администрация сайта имеет право в любое время без уведомления Пользователя вносить изменения в перечень Товаров и услуг, предлагаемых на Сайте, и (или) их цен. Документ указанный в пункте
                            <br/>5.6. настоящего Соглашения регулирует в соответствующей части и распространяют свое действие на использование Пользователем Сайта.
                            <br/>5.7. Политика конфиденциальности: <a href="/privacy_policy">Перейти</a>
                            <br/>5.8. Любой из документов, перечисленных в пункте 5.7 настоящего Соглашения может подлежать обновлению. Изменения вступают в силу с момента их опубликования на Сайте.
                        </p>
                        <p>
                            <br/>6. ОТВЕТСТВЕННОСТЬ
                            <br/>6.1. Любые убытки, которые Пользователь может понести в случае умышленного или неосторожного нарушения любого положения настоящего Соглашения, а также вследствие несанкционированного доступа к коммуникациям
                            другого Пользователя, Администрацией сайта не возмещаются.
                            <br/>6.2. Администрация сайта не несет ответственности за:
                            <br/>6.2.1. Задержки или сбои в процессе совершения операции, возникшие вследствие непреодолимой силы, а также любого случая неполадок в телекоммуникационных, компьютерных, электрических и иных смежных системах.
                            <br/>6.2.2. Действия систем переводов, банков, платежных систем и за задержки связанные с их работой.
                            <br/>6.2.3. Надлежащее функционирование Сайта, в случае, если
                            <br/>6.3. Пользователь не имеет необходимых технических средств для его использования, а также не несет никаких обязательств по обеспечению пользователей такими средствами.
                        </p>
                        <p>
                            <br/>7. НАРУШЕНИЕ УСЛОВИИ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ
                            <br/>7.1. Администрация сайта имеет право раскрыть информацию о Пользователе, если действующее законодательство Российской Федерации требует или разрешает такое раскрытие.
                            <br/>7.2. Администрация сайта вправе без предварительного уведомления Пользователя прекратить и (или) заблокировать доступ к Сайту, если Пользователь нарушил настоящее Соглашение или содержащиеся в иных
                            документах условия пользования Сайтом, а также в случае прекращения действия Сайта либо по причине технической неполадки или проблемы
                            <br/>7.3. Администрация сайта не несет ответственности перед Пользователем или третьими лицами за прекращение доступа к Сайту в случае нарушения Пользователем любого положения настоящего Соглашения или иного
                            документа, содержащего условия пользования Сайтом.
                        </p>
                        <p>
                            <br/>8. РАЗРЕШЕНИЕ СПОРОВ
                            <br/>8.1. В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения обязательным условием до обращения в суд является предъявление претензии (письменного предложения о добровольном
                            урегулировании спора).
                            <br/>8.2. Получатель претензии в течение 30 календарных дней со дня ее получения, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
                            <br/>8.3. При невозможности разрешить спор в добровольном порядке любая из Сторон вправе обратиться в суд за защитой своих прав, которые предоставлены им действующим законодательством Российской Федерации.
                            Любой иск в отношении условий использования Сайта должен быть предъявлен в течение 5 дней после возникновения оснований для иска, за исключением защиты авторских прав на охраняемые в соответствии с
                            законодательством материалы Сайта. При нарушении условий данного пункта любой иск оставляется судом без рассмотрения.
                        </p>
                        <p>
                            <br/>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ
                            <br/>9.1. Администрация сайта не принимает встречные предложения от Пользователя относительно изменений настоящего Пользовательского соглашения.
                            <br/>9.2. Отзывы Пользователя, размещенные на Сайте, не являются конфиденциальной информацией и могут быть использованы Администрацией сайта без ограничений.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    </>);
}
