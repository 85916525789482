import React from "react";

export default function ErrorNotFound() {
    return (<>
        <div className={"http-error-404-not-found"} />
        <div className="bg-violet h-screen object-cover bg-no-repeat bg-right" style={{backgroundImage: 'url(/images/heart.svg)'}}>
            <div className="container mx-auto h-full">
                <div className="flex sm:items-center h-full">
                    <div className="lg:w-7/12 lg:px-16">
                <span className="text-4xl text-white font-extrabold">
                    404
                </span>
                        <p className="text-white font-lg">Упс:( Кажется что-то пошло не так! Страница, которую вы запрашиваете, не существует. Возможно она устарела, была удалена, или был введен неверный адрес в адресной строке</p>
                        <a href="/" className="py-3 mt-12 block px-8 w-max text-white bg-red rounded font-lg font-bold
                                    hover:bg-red-light active:bg-red-saturated hover:shadow-red-light active:bg-red-saturated"
                        >ВЕРНУТЬСЯ НА ГЛАВНУЮ</a>
                    </div>
                </div>
            </div>
        </div>
    </>);
}