import React, { useEffect } from 'react';

import { Switch, Route } from 'react-router-dom';

import Index from './pages/Index';
import Cafe from './pages/Cafe';
import Cabinet from './pages/Cabinet';
import ThankYou from './pages/ThankYou';
import PaymentDone from './pages/PaymentDone';
import PaymentFailed from './pages/PaymentFailed';
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SEO from './pages/SEO';
import Header from "./components/Header";
import Footer from "./components/Footer";
import { fetchEvents, initPopup, selectMenu, selectPopup } from "./store";
import { useDispatch, useSelector } from "react-redux";
import Popup from "./components/Popup";
import SeoConfig from "./components/seo";
import MobileMenu from "./components/MobileMenu";

function App() {
    const dispatch = useDispatch();
    const popup = useSelector(selectPopup);
    const menu = useSelector(selectMenu);

    useEffect(() => {
        dispatch(fetchEvents());
    }, []);

    return <>
        <div id={"main"} className={popup.isOpen || menu.isOpen ? "filter blur-2xl overflow-hidden" : ""}>
            <Header/>
            <Switch>
                <Route exact path={"/"} component={Index}/>
                <Route exact path={"/cafe/:alias"} component={Cafe}/>
                <Route path={"/cabinet"} component={Cabinet}/>
                <Route path={"/thankyou.php"} component={ThankYou}/>
                <Route path={"/payment_done"} component={PaymentDone}/>
                <Route path={"/payment_failed"} component={PaymentFailed}/>
                <Route path={"/terms_of_service"} component={TermsOfService}/>
                <Route path={"/privacy_policy"} component={PrivacyPolicy}/>
                <Route path={"/:alias"} component={SEO}/>
            </Switch>
            <Footer seoLinks={SeoConfig}/>
        </div>
        <MobileMenu/>
        <Popup/>
    </>;
}

App.preInitStore = async (store, requestUrl) => {
    await store.dispatch(initPopup());
    await store.dispatch(fetchEvents());
    await Cafe.preInitStore(store);
    await SEO.preInitStore(store, requestUrl);
};
export default App;
